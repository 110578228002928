import React, { useState, useEffect, useContext } from "react";
import { makeRequest } from "../../api";
import TadobaContext from "../Context/TadobaContext";
import { useNavigate } from "react-router-dom";

const Toptigers = ({ selectedYear }) => {
  const api = useContext(TadobaContext);
  const navigate = useNavigate();
  const [topTigers, setTopTigers] = useState([]);
  const [id, setId] = useState(null);

  const fetchTopTigers = async (year = "all") => {
    let token = localStorage.token || "";

    try {
      const url =
        year === "all" || (Array.isArray(year) && year.length === 0)
          ? `api/v1/statistic/top_tigers_count/`
          : `api/v1/statistic/top_tigers_count/?year=${year}`;

      const result = await makeRequest("get", url);

      const result_data = result.data.data;
      setTopTigers(result_data);
      localStorage.setItem("top_tigers", JSON.stringify(result_data));

      let total = 0;
      result_data.forEach((item) => {
        total += item.no_of_images;
      });
      api.setTotalIdentifiedCount(total);
    } catch (error) {
      console.error("Error fetching top tigers:", error);
    }
  };

  useEffect(() => {
    const storedTopTigers = localStorage.top_tigers;

    if (!storedTopTigers) {
      fetchTopTigers();
    } else {
      let total = 0;
      const storedData = JSON.parse(storedTopTigers);
      storedData.forEach((item) => {
        total += item.no_of_images;
      });
      api.setTotalIdentifiedCount(total);
      setTopTigers(storedData);
    }
  }, []);

  useEffect(() => {
    const fetchByYear = async () => {
      if (api.selectedYearData) {
        if (api.selectedYearData === "all") {
          fetchTopTigers();
        } else {
          fetchTopTigers(api.selectedYearData);
        }
      }
    };

    fetchByYear();
  }, [api.selectedYearData]);

  const maxImages = Math.max(...topTigers.map((tiger) => tiger.no_of_images));

  const viewHandler = () => {
    api.setExpanded(false);
    navigate("./images/key/Tigerimages/" + id);
  };

  useEffect(() => {
    const tigerObject = api.allSpecies.find((item) => item.name === "Tiger");
    const tigerId = tigerObject ? tigerObject.id : null;
    setId(tigerId);
  }, []);

  return (
    <div className="Card" style={{ width: "160%" }}>
      <div className="Card_Heading">
        <div className="Card_title">
          <img src={require("../../Assets/Icons/top_tigers.png")} alt="" />
          <span>Top Tigers</span>
        </div>
        <div className="Card_Link" onClick={viewHandler}>
          {selectedYear !== "all" && (
            <span style={{ marginRight: "0.5vw", fontWeight: "600" }}>
              ({selectedYear})
            </span>
          )}
          <span>View all</span>
        </div>
      </div>
      <div className="top_tiger">
        <div className="top_tiger_Heading">
          <span>Tiger</span>
          <span>Images</span>
        </div>
        <div className="top_tiger_Content">
          {topTigers.map((tiger) => (
            <div
              className="top_tiger_Indi_Content"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "0.8rem",
                marginBottom: "8px",
                padding: "1px 2px",
              }}
              key={tiger.id}
            >
              <div style={{ display: "flex" }}>
                <span style={{ width: "4.5vw" }}>{tiger.name}</span>
                <div
                  className="top_tiger_Bar"
                  style={{
                    width: `${(tiger.no_of_images / maxImages) * 16}vw`,
                  }}
                ></div>
              </div>
              <span>{tiger.no_of_images}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Toptigers;
