import React, { useEffect, useState } from 'react';
import TadobaContext from "../Components/Context/TadobaContext";
import { useContext } from "react";
import { Radio, ConfigProvider, Select } from "antd";
import { DatePicker } from "antd";
import { makeRequest } from "../api";
import moment from "moment";
import MapView from "./Mapview"
import html2canvas from 'html2canvas';
import PolygonView from './PolygonView';
import Tiger_routes from "../Components/Upload/Tiger_routes";

const { RangePicker } = DatePicker;

const MapContainer = () => {
  const [showHeatMap, setShowHeatMap] = useState(true);
  const [tigerslist, setTigersList] = useState(() => {
    const savedTigerList = localStorage.getItem('tigerList');
    return savedTigerList ? JSON.parse(savedTigerList) : [];
  });
  const [camera, setCamera] = useState([]);
  const [CustomDate, setCustomDate] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [duration, setDuration] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isYearlyTigerChecked, setIsYearlyTigerChecked] = useState(false);
  const [isTigerNetworkChecked, setIsTigerNetworkChecked] = useState(false);

  const [buttonClicked, setButtonClicked] = useState(false);

  const [selectedSpecies, setSelectedSpecies] = useState(() => {
    const savedSpecies = localStorage.getItem('selectedSpecies');
    return savedSpecies ? JSON.parse(savedSpecies) : null;
  });

  const [selectedTiger, setSelectedTigers] = useState(() => {
    const savedTigers = localStorage.getItem('selectedTiger');
    return savedTigers ? JSON.parse(savedTigers) : null;
  });

  const [selectedCamera, setSelectedCamera] = useState(() => {
    const savedCamera = localStorage.getItem('selectedCamera');
    return savedCamera ? JSON.parse(savedCamera) : [];
  });

  const [mapLoading, setMapLoading] = useState(false);
  const [mapRef, setMapRef] = useState(null);

  const [id, setId] = useState(() => {
    const savedId = localStorage.getItem('id');
    return savedId ? JSON.parse(savedId) : "";
  });

  const yearList = ["2023", "2022", "2021"].map(year => ({
    label: year,
    value: year
  }));

  const toggleMap = () => {
    setShowHeatMap((prevShowHeatMap) => !prevShowHeatMap);
  };

  const [selectedYear, setSelectedYear] = useState("2023")
  const [selectedHeatMapYear, setSelectedHeatMapYear] = useState(null)

  useEffect(() => {
    const fetchTiger = async () => {
      let token = "";
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }

        const result = await makeRequest(
          "get",
          "api/v1/identification/species_tiger/"
        );
        const result_data = result.data.data;

        const names = result_data
          .map((tiger) => {
            if (tiger.images.length > 0) {
              return {
                value: tiger.id,
                label: tiger.name,
              };
            }
            return null;
          })
          .filter(Boolean);
        localStorage.setItem("tigerList", JSON.stringify(names))
        setTigersList(names);
      } catch (error) { }
    };
    fetchTiger();
  }, []);

  const customDateChangeHandler = (value) => {
    setSelectedCamera([]);
    setMarkers([]);
    setCamera([]);
    setSelectedHeatMapYear(null)
    setSelectedSpecies(null);
    setCustomDate(value);
    setSelectedTigers(null);
  };

  const durationChangeHandler = (event) => {
    setSelectedCamera([]);
    setMarkers([]);
    setCamera([]);
    setSelectedHeatMapYear(null)
    const today = moment();
    let startDate = "";
    let endDate = "";

    if (event.target.value === "last_month") {
      startDate = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      endDate = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
    } else if (event.target.value === "last_3_months") {
      startDate = moment()
        .subtract(3, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      endDate = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
    }

    setDuration(event.target.value);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleYearChange = (value) => {
    setSelectedHeatMapYear(value);
    setStartDate("")
    setEndDate("")
    setDuration("");
    setCustomDate(null)
  };

  const downloadMap = () => {
    if (mapRef) {
      mapRef.setOptions({
        zoom: 10,
        labels: true,
      });

      setMapLoading(true);

      setTimeout(() => {
        const mapDiv = mapRef.getDiv();

        const originalWidth = mapDiv.offsetWidth;
        const originalHeight = mapDiv.offsetHeight;

        html2canvas(mapDiv, {
          scale: 4,
          useCORS: true,
          width: originalWidth,
          height: originalHeight,
        }).then((canvas) => {
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = showHeatMap ? 'heat_map.png' : 'polygon_map.png';
          link.click();

          mapRef.setOptions({
            mapTypeId: 'roadmap',
            zoom: 10,
            labels: true,
          });

          setMapLoading(false);
        });
      }, 1000);
    }
  };

  if (buttonClicked === true) {
    return (
      <Tiger_routes
        tiger_id={selectedTiger}
        latitude={""}
        longitude={""}
        image={""}
        radio={"radio3"}
        uploaded_data={[]}
        origin={"map_view"}
      />
    );
  }

  return (
    <div className='map-container'>
      <ConfigProvider
        theme={{
          components: {
            Radio: {
              buttonSolidCheckedBg: "#E8DFD5",
              buttonSolidCheckedActiveBg: "#E8DFD5",
              buttonSolidCheckedHoverBg: "#E8DFD5",
              colorPrimary: "#FFF3E8",
              colorPrimaryHover: "#FFF3E8",
              colorPrimaryBorder: "#FFF3E8",
            },
            DatePicker: {
              activeBorderColor: "#FFF3E8",
              colorLinkActive: "#FFF3E8",
              colorPrimary: "#FFF3E8",
              hoverBorderColor: "#FFF3E8",
              colorPrimaryBorder: "#FFF3E8",
            },
            Select: {
              optionActiveBg: "#FFF3E8",
              optionSelectedBg: "#FFF3E8",
              // optionFontSize: 14,
              colorPrimaryHover: "#E67817",
              controlOutline: "#FFF3E8",
              multipleItemBg: "#FFF3E8",
            },
          },
        }}
      >
        <div className="map-setting-view">
          <div className="Main-title">
            <div className='Main-title-left'>
              <span>Map View</span>
              <div className="map-view-options">
                <label>
                  <input
                    type="radio"
                    value="heatmap"
                    checked={showHeatMap}
                    onChange={() => setShowHeatMap(true)}
                    style={{ marginRight: "0.2vw" }}
                  />
                  Heat Map View
                </label>
                <label>
                  <input
                    type="radio"
                    value="polygon"
                    checked={!showHeatMap}
                    onChange={() => setShowHeatMap(false)}
                    style={{ marginRight: "0.2vw" }}
                  />
                  Polygon Map View
                </label>
              </div>
            </div>
            {
              !buttonClicked && (
                <div style={{ display: "flex", gap: "1vw", width: "80%", alignItems: "center", justifyContent: "end" }}>
                  <div
                    className="dowload_button"
                  >
                    <button onClick={downloadMap}>
                      {mapLoading ? "Downloading..." : "Download Map"}
                    </button>
                  </div>
                  <div>
                    {
                      !showHeatMap && (
                        <div className="DirectionDropdown" style={{ display: "flex" }}>
                          <Select
                            showSearch
                            style={{ width: 150 }}
                            placeholder="Select Year"
                            size={"large"}
                            value={selectedYear}
                            defaultValue={"2023"}
                            onChange={setSelectedYear}
                            options={yearList}
                          />
                        </div>
                      )
                    }
                  </div>
                  {
                    showHeatMap && (
                      <>
                        <div style={{ maxWidth: "40vw" }}>
                          <Radio.Group
                            value={duration}
                            onChange={durationChangeHandler}
                            size="large"
                            buttonStyle="solid"
                            width={300}
                          >
                            <Radio.Button value="" disabled={isYearlyTigerChecked ? true : false}>All time</Radio.Button>
                            <Radio.Button value="last_month" disabled={isYearlyTigerChecked ? true : false}>Last Month</Radio.Button>
                            <Radio.Button value="last_3_months" disabled={isYearlyTigerChecked ? true : false}>Last 3 months</Radio.Button>

                            {/* Select Year integrated into the Radio button */}
                            <Radio.Button value="select_year" disabled={true}>
                              <Select
                                showSearch
                                style={{ width: 150 }}
                                placeholder="Select Year"
                                size="large"
                                value={selectedHeatMapYear}
                                defaultValue={"2023"}
                                onChange={handleYearChange}
                                allowClear={true}
                                options={yearList}
                                bordered={false}
                              />
                            </Radio.Button>

                            <Radio.Button value="custom" disabled={isYearlyTigerChecked ? true : false}>
                              {duration === "custom" ? (
                                <RangePicker
                                  value={CustomDate}
                                  format="YYYY-MM-DD"
                                  onClick={() => {
                                    setDuration("custom");
                                  }}
                                  onChange={customDateChangeHandler}
                                  bordered={false}
                                  size="large"
                                  onClose={() => {
                                    setCustomDate(null);
                                    setStartDate("");
                                    setEndDate("");
                                  }}
                                />
                              ) : (
                                "Custom"
                              )}
                            </Radio.Button>
                          </Radio.Group>
                        </div>
                      </>
                    )
                  }

                </div>
              )
            }
          </div>
          <div className='map-setting-view-container'>
            {
              showHeatMap ? (<MapView
                tigerslist={tigerslist}
                camera={camera}
                setCamera={setCamera}
                CustomDate={CustomDate}
                setCustomDate={setCustomDate}
                markers={markers}
                setMarkers={setMarkers}
                duration={duration}
                setDuration={setDuration}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                isYearlyTigerChecked={isYearlyTigerChecked}
                setIsYearlyTigerChecked={setIsYearlyTigerChecked}
                selectedSpecies={selectedSpecies}
                setSelectedSpecies={setSelectedSpecies}
                selectedTiger={selectedTiger}
                setSelectedTigers={setSelectedTigers}
                selectedCamera={selectedCamera}
                setSelectedCamera={setSelectedCamera}
                customDateChangeHandler={customDateChangeHandler}
                durationChangeHandler={durationChangeHandler}
                downloadMap={downloadMap}
                id={id}
                setId={setId}
                mapRef={mapRef}
                setMapRef={setMapRef}
                selectedYear={selectedHeatMapYear}
                isTigerNetworkChecked={isTigerNetworkChecked}
                setIsTigerNetworkChecked={setIsTigerNetworkChecked}
                buttonClicked={buttonClicked}
                setButtonClicked={setButtonClicked}
              />) : (
                <PolygonView
                  tigerslist={tigerslist}
                  mapRef={mapRef}
                  setMapRef={setMapRef}
                  selectedYear={selectedYear}
                />
              )
            }
          </div>
        </div>
      </ConfigProvider >

    </div>
  );
};

export default MapContainer;
