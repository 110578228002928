import React, { useDebugValue, useState } from "react";
import TadobaContext from "../Context/TadobaContext";
import { useContext } from "react";
import { MapKey } from "../../Data/Data";
import {
  GoogleMap,
  Marker,
  Circle,
  LoadScript,
  Polygon,
} from "@react-google-maps/api";
import LazyImage from "../LazyImage";

const TigerConflictView = (props) => {
  const api = useContext(TadobaContext);
  const body = props.row;
  const [position, setPosition] = useState(null);
  const [marker, setMarker] = useState([
    { lat: parseFloat(body.Latitude), lng: parseFloat(body.Longitude) },
  ]);

  console.log(body.Image);

  const handleMapLoad = (map) => {
    setPosition({
      lat: map.latLng.lat(),
      lng: map.latLng.lng(),
    });
  };

  console.log(marker);

  const handleMarkerDragEnd = (e) => {};

  return (
    <div className="PopupEditRole" style={{ width: "1200px" }}>
      <div className="popupEditContent">
        <div className="popupEditHeader">
          <div>
            <span>Tiger Conflict View</span>
          </div>
          <div onClick={props.close}>
            <img src={require("../../Assets/Icons/Close.png")} alt="" />
          </div>
        </div>
        <div style={{ marginTop: "-30px" }}>
          <div className="SpeciesGroup">
            <div className="Card MapCard">
              <div className="Map-View">
                <LoadScript googleMapsApiKey={MapKey}>
                  <GoogleMap
                    mapContainerStyle={{ width: "95%", height: "100%" }}
                    center={
                      position === null
                        ? { lat: 20.242352777, lng: 79.409575 }
                        : position
                    }
                    zoom={10}
                    onClick={handleMapLoad}
                  >
                    <Polygon
                      paths={api.polygonCoordinates}
                      options={{
                        fillColor: "skyblue",
                        fillOpacity: 0.4,
                        strokeColor: "blue",
                        strokeOpacity: 0.6,
                        strokeWeight: 2,
                      }}
                      onClick={handleMapLoad}
                    />
                    {marker.map((value, index) => (
                      <React.Fragment key={index}>
                        <>
                          <Marker
                            title={``}
                            icon={require("../../Assets/Icons/ckl.png")}
                            animation={true}
                            position={value}
                            onDragEnd={handleMarkerDragEnd}
                          />
                        </>
                      </React.Fragment>
                    ))}
                  </GoogleMap>
                </LoadScript>
              </div>
              <div className="Map-Right">
                <div className="image-container">
                  <LazyImage
                    src={body.Image}
                    alt="Tiger Image"
                    app="tiger_view"
                  />
                </div>
                <h2 style={{ marginTop: "20px", marginLeft: "10px" }}>
                  {body["Tiger Name"]}
                </h2>
              </div>
            </div>
            <div className="Image_Edit_Mode">
              <div className="Image_Edit_Data" style={{ width: "1000px" }}>
                <div className="GeolocationDetails">
                  <div className="GeolocationHeader">
                    Conflict Geolocation Details
                  </div>
                  <div className="GeolocationDetailsEditContent">
                    <div className="datacard" style={{ width: "20%" }}>
                      <div className="cardheader">
                        <h4>Range</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">{body.Range}</div>
                    </div>
                    <div className="datacard" style={{ width: "20%" }}>
                      <div className="cardheader">
                        <h4>Village</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">{body.Village}</div>
                    </div>

                    <div className="datacard" style={{ width: "20%" }}>
                      <div className="cardheader">
                        <h4>Latitude</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">{body.Latitude}</div>
                    </div>
                    <div className="datacard" style={{ width: "20%" }}>
                      <div className="cardheader">
                        <h4>Longitude</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">{body.Latitude}</div>
                    </div>
                    <div className="datacard" style={{ width: "25%" }}>
                      <div className="cardheader">
                        <h4>Conflict Date</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">{body["Conflict Date"]}</div>
                    </div>
                    <div className="datacard" style={{ width: "25%" }}>
                      <div className="cardheader">
                        <h4>Comments</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">{body.Comments}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TigerConflictView;
