import React from "react";
import Statistics_card from "../Components/Cards/Statistics_card";
import TopSpecies_Card from "../Components/Cards/TopSpecies_Card";
import AverageUploadLineGraph from "../Components/Cards/AverageUploadLineGraph";
import Unidentified from "../Components/Cards/Unidentified";
import Toptigers from "../Components/Cards/Toptigers";
import AlertReport_Card from "../Components/Cards/AlertReport_Card";
import Upload_Timeline from "../Components/Cards/Upload_Timeline";
const Statistics = () => {
  return (
    <div className="Dashboard">
      <div className="Setting_User">
        <div className="Setting_User_Header">
          <div>
            <span>Statistics</span>
          </div>
          <div className="Setting_User_Attribute">
            {/* <div className='role_filter'>
              <img src={require('../Assets/Icons/filter.png')} alt="" />
            </div> */}
            {/* <div className="role_filter">
              <img src={require("../Assets/Icons/Calander.png")} alt="" />
            </div> */}
          </div>
        </div>
      </div>
      <div style={{ marginTop: "24px" }}>
        <Statistics_card />
      </div>
      <div style={{ display: "flex", gap: "24px", marginTop: "24px" }}>
        <TopSpecies_Card />
        <Toptigers />
      </div>
      <div style={{ marginTop: "24px" }}>
        <Upload_Timeline />
      </div>
      <div style={{ display: "flex", gap: "24px", marginTop: "24px" }}>
        <AverageUploadLineGraph />
        <Unidentified />
      </div>
      {/* <div style={{ marginTop: "24px" }}>
        <AlertReport_Card />
      </div> */}
    </div>
  );
};

export default Statistics;
