import React, { useContext, useEffect, useState, useRef } from "react";
import ImageContainer from "../ImageContainer";
import axios from "axios";
import { Map_Images_Tiger_Template, url } from "../../Data/Data";
import { useNavigate } from "react-router-dom";
import TadobaContext from "../Context/TadobaContext";
import { useParams } from "react-router-dom";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import ImagesTable from "../ImagesTable";
import { Images_Template } from "../../Data/Data";
import { Map_Images_Template } from "../../Data/Data";
import { makeRequest } from "../../api";

const MapTable = (props) => {
  const species_id = props.speciesID;
  const tiger_types = props.tiger_types;
  const geolocationBeat = props.geolocationBeat;
  const start_date = props.start_date;
  const end_date = props.end_date;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [render, setRender] = useState(1);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const isInitialRender = useRef(true);
  const [scroll, setScroll] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [listData, setListData] = useState([]);
  const [grid, stGrid] = useState(true);
  const [exportedImages, setExportedImages] = useState([]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      setLoading(true);
      setData([]);
      setListData([]);
      setCurrentPage(0);
      setExportedImages([]);
      setPage(1);
      setRender(render + 1);
    }
  }, [species_id, tiger_types, props.selectedCamera, props.selectedYear]);

  useEffect(() => {
    filterApply();
  }, [render, page]);

  const getImages = async () => {
    setScroll(true);
    if (page <= currentPage) {
      setLoading(false);
      return;
    }
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/statistic/image_list_classfied_species/?q=${species_id}&page=${page}&size=20`
      );
      const tempData = result.data.data;
      const newData = tempData.map((row, key) => {
        const obj = { ...row };
        obj.checked = false;
        return obj;
      });
      setCount(result.data.count);
      if (newData.length === 0) {
      } else {
        const formattedList = [];

        for (const cameraData of newData) {
          const name = cameraData?.id;
          const species = cameraData?.species?.name;
          const dateString = cameraData.created_at;
          const dateObj = new Date(dateString);
          const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "UTC",
          };

          const formattedDate = new Intl.DateTimeFormat(
            "en-US",
            options
          ).format(dateObj);
          const formattedData = {
            Name: name,
            Species: species || "Unclassified",
            "Station ID": cameraData.station.name.toString(),
            "Camera ID": cameraData.camera.name.toString(),
            "Date and Time": formattedDate,
            image: cameraData.image,
            id: cameraData.id,
            image_key: cameraData.image_key,
            checked: false,
            longitude: cameraData.location.longitude,
            latitude: cameraData.location.latitude,
            "Tiger Id": cameraData.specie_sub_type.name || "Unidentified",
            Beat: cameraData.beat.name.toString(),
            Range: cameraData.range.name.toString(),
            "Compartment no.": cameraData.compartment.name.toString(),
          };
          formattedList.push(formattedData);
        }
        setListData((prevData) => [...prevData, ...formattedList]);
        setData((prevData) => [...prevData, ...newData]);
        setCurrentPage(currentPage + 1);
        setLoading(false);
        setScroll(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const filterApply = async () => {
    const geolocationRange = [];
    const geolocationRound = [];
    const geolocationCompartment = [];
    const geolocationStation = [];
    const geolocationCamera = [];
    const gender = [];
    const status = [];
    const age = [];
    const size = 20;
    const start_date = props.start_date;
    const end_date = props.end_date;
    props.camera
      .filter((item) => props.selectedCamera.includes(item.value))
      .map((val, key) => {
        geolocationStation.push(val.id);
        return;
      });
    const result_data = {
      geolocation_range: geolocationRange,
      geolocation_round: geolocationRound,
      geolocation_beat: geolocationBeat,
      geolocation_compartment: geolocationCompartment,
      geolocation_station: geolocationStation,
      geolocation_camera: geolocationCamera,
      species: species_id !== undefined ? [species_id] : [],
      tiger_types: [tiger_types],
      status: status,
      age: age,
      gender: gender,
      page: page,
      size: size,
      start_date: start_date,
      end_date: end_date,
      ...(props.selectedYear ? { years: [props.selectedYear]} : {})
    };

    setScroll(true);
    if (page <= currentPage) {
      setLoading(false);
      return;
    }
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "post",
        `api/v1/classification/image_filter/`,
        result_data
      );
      const tempData = result.data.data;
      // setfilterdata(tempData);
      const newData = tempData.map((row, key) => {
        const obj = { ...row };
        obj.checked = false;
        return obj;
      });
      setCount(result.data.count);
      if (newData.length === 0) {
      } else {
        const formattedList = [];

        for (const cameraData of newData) {
          const name = cameraData?.id;
          const species = cameraData?.species?.name;
          const dateString = cameraData.captured_at;
          const dateObj = new Date(dateString);
          const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "UTC",
          };

          const formattedDate = new Intl.DateTimeFormat(
            "en-US",
            options
          ).format(dateObj);
          const formattedData = {
            Name: name,
            Species: species || "Unclassified",
            "Station ID": cameraData.station.name.toString(),
            "Camera ID": cameraData.camera.name.toString(),
            "Date and Time": formattedDate,
            image: cameraData.image,
            id: cameraData.id,
            image_key: cameraData.image_key,
            checked: false,
            longitude: cameraData.location.longitude,
            latitude: cameraData.location.latitude,
            "Tiger Id": cameraData?.specie_sub_type?.name || "Unidentified",
            Beat: cameraData.beat.name.toString(),
            Range: cameraData.range.name.toString(),
            "Compartment no.": cameraData.compartment.name.toString(),
          };
          formattedList.push(formattedData);
        }
        setListData((prevData) => [...prevData, ...formattedList]);
        setData((prevData) => [...prevData, ...newData]);
        setCurrentPage(currentPage + 1);
        setLoading(false);
        setScroll(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="Setting_User">
      {/* <div className="Setting_User_Header">
        <div><span>Images</span></div>
        <div className='Setting_User_Attribute'>
          {exportedImages.length != 0 ?
                        <button className='role_filter' onClick={downloadImages}>Export</button> : ""}
        </div>
      </div>  */}
      <ImagesTable
        table={"map"}
        speciesID={species_id}
        exportedImages={exportedImages}
        scroll={scroll}
        setExportedImages={setExportedImages}
        currentPage={currentPage}
        grid={grid}
        setGrid={stGrid}
        setCurrentPage={setCurrentPage}
        page={page}
        setPage={setPage}
        setData={setListData}
        count={count}
        data={listData}
        getImages={getImages}
        type={"map"}
        template={Map_Images_Tiger_Template}
        loading={loading}
      />
    </div>
  );
};

export default MapTable;
