import React, { useEffect, useState } from "react";
import { MapKey } from "../Data/Data";
import {
  GoogleMap,
  Marker,
  Circle,
  LoadScript,
  Polygon,
} from "@react-google-maps/api";
import TadobaContext from "../Components/Context/TadobaContext";
import { useContext } from "react";
import { ConfigProvider } from "antd";
import TigerTable from "../Components/Map/TigerTable";
import LazyImage from "../Components/LazyImage";
import { makeRequest } from "../api";
import { toast } from "react-toastify";
import { warningToaster } from "../Data/Data";
import { Select } from "antd";
import leftArrowIcon from "../Assets/Icons/left_arrow.png";
import rightArrowIcon from "../Assets/Icons/right_arrow.png";
import { useNavigate } from "react-router-dom";
import LostTigers from "./LostTigers";
import Mapview from "./Mapview";
import DownloadTigerData from "../Components/DownloadTigerData";
import html2canvas from "html2canvas";
import BackButton from "../Components/BackButton";
import ClipLoader from "react-spinners/ClipLoader";
import MapContainer from "../Containers/MapContainer";

const TigerView = (props) => {
  const tiger_id = props.tiger_id;
  const lati = parseFloat(props.latitude);
  const longi = parseFloat(props.longitude);
  const uploadedImage = props.image;
  const radio = props.radio;
  const origin = props.origin;

  const api = useContext(TadobaContext);
  const [selectedSpecies, setSelectedSpecies] = useState();
  const [markers, setMarkers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tigerOptionLoading, setTigerOptionLoading] = useState(false);
  const [position, setPosition] = useState(null);
  const [tigerslist, setTigersList] = useState([]);
  const [geolocationData, setGeolocationData] = useState({});
  const [allTigerData, setAllTigerData] = useState([]);
  const [basicTigerDetails, setBasicTigerDetails] = useState({});
  const [currentLatitude, setCurrentLatitude] = useState(lati || null);
  const [currentLongitude, setCurrentLongitude] = useState(longi || null);
  const [uploadedData, setUploadedData] = useState(props.uploaded_data || null);
  const [showImage, SetShowImage] = useState(uploadedImage || null);
  const [tigerName, setTigerName] = useState(null);
  const [nearestMarkers, setNearestMarkers] = useState([]);
  const [nearestTigerCount, setNearestTigerCount] = useState(null);
  const [prevTigerCount, setPrevTigerCount] = useState(null);
  const [herbCount, setHerbCount] = useState(null);
  const [prevHerbCount, setPrevHerbCount] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [button, setButton] = useState(false);
  const navigate = useNavigate();

  const [mapRef, setMapRef] = useState(null);

  const onLoad = (map) => {
    setMapRef(map);
  };

  const downloadMap = () => {
    if (mapRef) {
      mapRef.setOptions({
        zoom: 10,
        labels: true,
      });

      setTimeout(() => {
        const mapDiv = mapRef.getDiv();

        const originalWidth = mapDiv.offsetWidth;
        const originalHeight = mapDiv.offsetHeight;

        html2canvas(mapDiv, {
          scale: 4,
          useCORS: true,
          width: originalWidth,
          height: originalHeight,
        }).then((canvas) => {
          const link = document.createElement("a");
          link.href = canvas.toDataURL("image/png");
          link.download = "species_map.png";
          link.click();

          mapRef.setOptions({
            mapTypeId: "roadmap",
            zoom: 10,
            labels: true,
          });
        });
      }, 1000);
    }
  };

  const SetAllLocations = (currentloc, markers) => {
    const allMarkers = [...currentloc, ...markers];
    setMarkers(allMarkers);
  };
  // console.log(uploadedData);
  const getZone = async (item) => {
    const loc = item.map((value, index) => {
      return {
        lat: value.location.latitude,
        label: index + 1,
        lng: value.location.longitude,
        count: 1,
      };
    });
    const currentlocation = [
      {
        lat: parseFloat(currentLatitude),
        label: "current location",
        lng: parseFloat(currentLongitude),
        count: 1,
      },
    ];
    SetAllLocations(currentlocation, loc);
    setLoading(false);
  };

  const speciesChangeHandler = (event) => {
    setSelectedSpecies(event);
    setCurrentLatitude(null);
    setCurrentLongitude(null);
    SetShowImage(null);
    fetchGeolocationData(event);
    getBasicDetails(event, allTigerData);
    setLoading(true);
  };

  const getBasicDetails = (id, allTigerData) => {
    for (let item of allTigerData) {
      if (item.id === id) {
        const basicdetails = {
          speciesStatus: item.species_status.name,
          speciesAge: item.species_age.name,
          speciesGender: item.species_gender.name,
        };

        setBasicTigerDetails(basicdetails);
      }
    }
  };

  function getNameById(id) {
    const foundObject = allTigerData.find((item) => item.id === id);
    if (foundObject) {
      setSelectedSpecies(foundObject.id);
      setTigerName(foundObject.name);
      return foundObject.name;
    } else {
      return null;
    }
  }

  useEffect(() => {
    getNameById(selectedSpecies);
  }, [selectedSpecies]);

  useEffect(() => {
    const fetchTiger = async () => {
      let token = "";
      setTigerOptionLoading(true);
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }

        const result = await makeRequest(
          "get",
          "api/v1/identification/species_tiger/"
        );
        const result_data = result.data.data;

        setAllTigerData(result_data);
        const names = result_data
          .map((tiger) => {
            if (tiger.images.length > 0) {
              return {
                value: tiger.id,
                label: tiger.name,
              };
            }
            return null;
          })
          .filter(Boolean);

        setTigersList(names);
        setTigerOptionLoading(false);
      } catch (error) {
        toast.success("Error While getting Tiger data", warningToaster);
      }
    };
    fetchTiger();
  }, []);

  const fetchGeolocationData = async (id) => {
    if (tiger_id || id) {
      let token = "";

      try {
        if (localStorage.token) {
          token = localStorage.token;
        }
        const data = {
          tiger_id: id,
          latitude: currentLatitude,
          longitude: currentLongitude,
        };
        const result = await makeRequest(
          "post",
          "api/v1/identification/last_known_info/",
          data
        );
        const result_data = result.data.data;

        if (Object.keys(result_data).length === 0) {
          toast.success(
            "No Data available for the selected tiger",
            warningToaster
          );
        } else {
          if (!lati && !longi) {
            nearestTigers(
              result_data.last_seen_data.species_data_id,
              result_data.last_seen_data.latitude,
              result_data.last_seen_data.longitude
            );
            nearestHerbs(
              result_data.last_seen_data.latitude,
              result_data.last_seen_data.longitude
            );
          }
        }
        setGeolocationData(result_data);
      } catch (error) {
        setGeolocationData([]);
        setBasicTigerDetails([]);
        setNearestTigerCount(0);
        setHerbCount(0);
        toast.success("Error While getting Tiger data", warningToaster);
      }
    }
  };

  const nearestTigers = async (id, currentLatitude, currentLongitude) => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const data = {
        tiger_id: id,
        lati: currentLatitude,
        longi: currentLongitude,
      };
      const result = await makeRequest(
        "post",
        "api/v1/identification/nearest_tigers/",
        data
      );
      const result_data = result.data.data;
      setNearestTigerCount(result_data.length);
      setPrevTigerCount(result.data.prev_tigers);

      const loc = [];
      result_data.forEach((item) => {
        const speciesDataId = item.species_data_id;
        item.locations.forEach((location, index) => {
          const newLocation = {
            lat: location.lati,
            label: `nearestTiger: Id-${speciesDataId}`,
            lng: location.longi,
            count: 1,
          };
          loc.push(newLocation);
        });
      });

      setNearestMarkers(loc);
    } catch (error) {
      console.log(error);
    }
  };

  const nearestHerbs = async (currentLatitude, currentLongitude) => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const data = {
        lati: currentLatitude,
        longi: currentLongitude,
      };
      const result = await makeRequest(
        "post",
        "api/v1/identification/nearest_herb/",
        data
      );
      const result_data = result.data.data;
      setHerbCount(result_data.herb_count);
      setPrevHerbCount(result_data.herb_count_prev);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchGeolocationData(tiger_id);
    getNameById(tiger_id, allTigerData);
    getBasicDetails(tiger_id, allTigerData);
  }, [
    tiger_id,
    selectedSpecies,
    currentLatitude,
    currentLongitude,
    allTigerData,
  ]);

  useEffect(() => {
    if (currentLatitude) {
      nearestTigers(tiger_id, currentLatitude, currentLongitude);
      nearestHerbs(currentLatitude, currentLongitude);
    }
  }, [tiger_id, currentLatitude, currentLongitude]);

  const handleMarkerDragEnd = (e) => {};

  const handleMapLoad = (map) => {
    setPosition({
      lat: map.latLng.lat(),
      lng: map.latLng.lng(),
    });
  };

  const handleDragEnd = () => {
    if (mapRef) {
      const newCenter = mapRef.getCenter();
      setPosition({
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      });
    }
  };

  const handleLeftArrowClick = () => {
    const imagesCount = geolocationData?.image?.length || 0;
    if (imagesCount > 0) {
      setSelectedImageIndex((prevIndex) => {
        if (prevIndex > 0) {
          return prevIndex - 1;
        } else {
          return imagesCount - 1;
        }
      });
    }
  };

  const handleRightArrowClick = () => {
    const imagesCount = geolocationData?.image?.length || 0;
    if (imagesCount > 0) {
      setSelectedImageIndex((prevIndex) => {
        if (prevIndex < imagesCount - 1) {
          return prevIndex + 1;
        } else {
          return 0;
        }
      });
    }
  };

  const handleBack = () => {
    setButton(true);
  };

  if (button) {
    if (origin === "lostTigers") {
      return <LostTigers />;
    } else if (origin === "map_view") {
      return <MapContainer />;
    }
  }
  if (props.types === "upload_tiger") {
    if (!selectedSpecies || !basicTigerDetails || !geolocationData || loading) {
      return (
        <div className="loading-mask">
          <div style={{ textAlign: "center" }}>
            <ClipLoader
              color="green"
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <p style={{ marginTop: "10px", color: "green" }}>Loading Data...</p>
          </div>
        </div>
      );
    }
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            buttonSolidCheckedBg: "#E8DFD5",
            buttonSolidCheckedActiveBg: "#E8DFD5",
            buttonSolidCheckedHoverBg: "#E8DFD5",
            colorPrimary: "#FFF3E8",
            colorPrimaryHover: "#FFF3E8",
            colorPrimaryBorder: "#FFF3E8",
          },
          DatePicker: {
            activeBorderColor: "#FFF3E8",
            colorLinkActive: "#FFF3E8",
            colorPrimary: "#FFF3E8",
            hoverBorderColor: "#FFF3E8",
            colorPrimaryBorder: "#FFF3E8",
          },
          Select: {
            optionActiveBg: "#FFF3E8",
            optionSelectedBg: "#FFF3E8",
            optionFontSize: 14,
            colorPrimaryHover: "#E67817",
            controlOutline: "#FFF3E8",
            multipleItemBg: "#FFF3E8",
          },
        },
      }}
    >
      <div className="map-setting-view">
        <div className="Main-title" style={{ marginBottom: "10px" }}>
          <div className="Main-title-left">
            {/* <BackButton setButtonClicked={props.setButtonClicked} buttonClicked={props.buttonClicked} /> */}
            <span>Tiger View</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "1vw",
              width: !selectedSpecies ? "25%" : "60vw",
            }}
          >
            {selectedSpecies &&
              basicTigerDetails &&
              Object.keys(basicTigerDetails).length > 0 &&
              geolocationData &&
              Object.keys(geolocationData).length > 0 &&
              !loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "1vw",
                  }}
                >
                  <DownloadTigerData
                    tigerName={tigerName}
                    basicTigerDetails={basicTigerDetails}
                    geolocationData={geolocationData}
                    herbCount={herbCount}
                    nearestTigerCount={nearestTigerCount}
                    speciesID={[]}
                    geolocationBeat={[]}
                    tiger_types={selectedSpecies}
                    downloadMap={downloadMap}
                    latitude={
                      currentLatitude ||
                      (markers.length >= 2 ? markers[0].lat : null)
                    }
                    longitude={
                      currentLongitude ||
                      (markers.length >= 2 ? markers[0].lng : null)
                    }
                    onTigerTableData={getZone}
                    type={props.tiger_id ? "upload_flow" : null}
                  />
                </div>
              )}
            {!tiger_id && (
              <div className="filter-options">
                <div
                  className="DirectionDropdown"
                  style={{ width: "100%", marginTop: "-5vh" }}
                >
                  <label htmlFor="">Tigers</label>
                  <Select
                    showSearch
                    style={{ width: 250 }}
                    placeholder="Select"
                    optionFilterProp="children"
                    size={"large"}
                    value={selectedSpecies}
                    onChange={speciesChangeHandler}
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    options={tigerslist}
                    loading={tigerOptionLoading}
                    disabled={tigerOptionLoading}
                  />
                </div>
              </div>
            )}
            {(origin === "losttigers" || origin === "map_view") && (
              <button className="tigertview_back_button" onClick={handleBack}>
                Back
              </button>
            )}
          </div>
        </div>
        <div className="SpeciesGroup">
          <div style={{ paddingBottom: "0px" }} className="Card MapCard">
            <div className="Map-View">
              <LoadScript googleMapsApiKey={MapKey}>
                <GoogleMap
                  mapContainerStyle={{ width: "95%", height: "100%" }}
                  center={
                    position === null
                      ? { lat: 11.962482, lng: 77.15502 }
                      : position
                  }
                  zoom={10}
                  onClick={handleMapLoad}
                  onLoad={onLoad}
                  onDragEnd={handleDragEnd}
                >
                  <Polygon
                    paths={api.polygonCoordinates}
                    options={{
                      fillColor: "skyblue",
                      fillOpacity: 0.4,
                      strokeColor: "blue",
                      strokeOpacity: 0.6,
                      strokeWeight: 2,
                    }}
                    onClick={handleMapLoad}
                  />
                  <>
                    {markers && nearestMarkers && (
                      <>
                        {[...nearestMarkers, ...markers].map(
                          (value, index, array) => (
                            <React.Fragment key={index}>
                              {value.label !== "current location" && (
                                <>
                                  {index ===
                                    array.length - markers.length + 1 &&
                                    currentLatitude === null && (
                                      <Marker
                                        title={`index:${value.label}`}
                                        icon={require("../Assets/Icons/ckl.png")}
                                        animation={true}
                                        position={value}
                                        onDragEnd={handleMarkerDragEnd}
                                      />
                                    )}
                                  {value.label === 1 && (
                                    <Marker
                                      title={`index:${value.label}`}
                                      icon={require("../Assets/Icons/ckl.png")}
                                      animation={true}
                                      position={value}
                                      onDragEnd={handleMarkerDragEnd}
                                    />
                                  )}
                                  {typeof value.label === "string" &&
                                    value.label.includes("nearestTiger") && (
                                      <Marker
                                        title={`index:${value.label}`}
                                        icon={require("../Assets/Icons/yellow_marker.png")}
                                        animation={true}
                                        position={value}
                                        onDragEnd={handleMarkerDragEnd}
                                      />
                                    )}

                                  {!(
                                    typeof value.label === "string" &&
                                    value.label.includes("nearestTiger")
                                  ) &&
                                    value.label !== 1 && (
                                      <Marker
                                        title={`index:${value.label}`}
                                        icon={require("../Assets/Icons/marker.png")}
                                        animation={true}
                                        position={value}
                                        onDragEnd={handleMarkerDragEnd}
                                      />
                                    )}

                                  {index ===
                                    array.length - markers.length + 1 &&
                                    currentLatitude === null && (
                                      <Circle
                                        center={value}
                                        radius={5000}
                                        options={{
                                          fillColor: "green",
                                          fillOpacity: 0.2,
                                          strokeColor: "green",
                                          strokeOpacity: 0.5,
                                          strokeWeight: 2,
                                        }}
                                      />
                                    )}
                                </>
                              )}
                            </React.Fragment>
                          )
                        )}
                        {markers.map(
                          (value, index) =>
                            value.label === "current location" && (
                              <React.Fragment key={index}>
                                <Marker
                                  title={`index:${value.label}`}
                                  icon={require("../Assets/Icons/current_marker.png")}
                                  animation={true}
                                  position={value}
                                  onDragEnd={handleMarkerDragEnd}
                                />

                                <Circle
                                  center={value}
                                  radius={5000}
                                  options={{
                                    fillColor: "green",
                                    fillOpacity: 0.2,
                                    strokeColor: "green",
                                    strokeOpacity: 0.5,
                                    strokeWeight: 2,
                                  }}
                                />
                              </React.Fragment>
                            )
                        )}
                      </>
                    )}
                  </>
                </GoogleMap>
              </LoadScript>
            </div>
            <div className="Map-Right">
              <div className="image-container">
                {showImage || geolocationData.image ? (
                  <LazyImage
                    src={
                      showImage
                        ? showImage
                        : geolocationData.image[selectedImageIndex]
                    }
                    alt="Tiger Image"
                    app="tiger_view"
                  />
                ) : (
                  <div className="loading-indicator">
                    Select a tiger to view details....
                  </div>
                )}
                {!showImage && geolocationData?.image?.length > 1 && (
                  <div className="arrows-container ">
                    <img
                      src={leftArrowIcon}
                      alt="Left Arrow"
                      className="left-arrow"
                      onClick={handleLeftArrowClick}
                      style={{ marginLeft: "4px" }}
                    />
                    <img
                      src={rightArrowIcon}
                      alt="Right Arrow"
                      className="right-arrow"
                      onClick={handleRightArrowClick}
                    />
                  </div>
                )}
              </div>
              <h2 style={{ marginTop: "5px" }}>{tigerName}</h2>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "5px" }}
          >
            <img
              src={require("../Assets/Icons/current_marker.png")}
              alt="Current Marker"
              style={{ width: "1.4%", marginLeft: "30px" }}
            />
            <span
              style={{
                display: "inline",
                fontSize: "11px",
                marginRight: "20px",
                marginLeft: "5px",
                verticalAlign: "middle",
              }}
            >
              Current Location
            </span>
            <img
              src={require("../Assets/Icons/ckl.png")}
              alt="Current Marker"
              style={{ width: "1.4%", marginRight: "5px" }}
            />
            <span
              style={{
                display: "inline",
                fontSize: "11px",
                verticalAlign: "middle",
              }}
            >
              Last Known Loc.
            </span>
            <img
              src={require("../Assets/Icons/marker.png")}
              alt="Marker"
              style={{ width: "1.5%", marginRight: "5px", marginLeft: "15px" }}
            />
            <span
              style={{
                display: "inline",
                fontSize: "11px",
                verticalAlign: "middle",
              }}
            >
              {"    "}Previous Sightings
            </span>
            <img
              src={require("../Assets/Icons/yellow_marker.png")}
              alt="Current Marker"
              style={{ width: "1.4%", marginRight: "5px", marginLeft: "15px" }}
            />
            <span
              style={{
                display: "inline",
                fontSize: "11px",
                verticalAlign: "middle",
              }}
            >
              Nearest Tigers
            </span>
            <img
              src={require("../Assets/Icons/radius.png")}
              alt="Current Marker"
              style={{ width: "1.4%", marginRight: "5px", marginLeft: "15px" }}
            />
            <span
              style={{
                display: "inline",
                fontSize: "11px",
                verticalAlign: "middle",
              }}
            >
              Herbivorous
            </span>
          </div>

          <div className="Image_Edit_Mode">
            <div className="Image_Edit_Data">
              <div className="Image_Edit_Data">
                <div className="GeolocationDetails">
                  <div className="GeolocationHeader">
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <img
                        className="cardicon"
                        src={require("../Assets/Icons/basic_details.png")}
                        alt="Distance Icon"
                        title=""
                        style={{ width: "25px", marginRight: "10px" }}
                      />
                      Basic Details
                    </span>
                  </div>
                  <div className="GeolocationDetailsEditContent">
                    <div className="datacard" style={{ width: "18%" }}>
                      <div className="cardheader">
                        <h4>Gender</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">
                        {basicTigerDetails?.speciesGender}
                      </div>
                    </div>
                    <div className="datacard" style={{ width: "18%" }}>
                      <div className="cardheader">
                        <h4>Age</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">
                        {basicTigerDetails?.speciesAge}
                      </div>
                    </div>
                    <div className="datacard" style={{ width: "18%" }}>
                      <div className="cardheader">
                        <h4>
                          Status{" "}
                          <img
                            className="cardicon"
                            src={require("../Assets/Icons/i.png")}
                            alt="Distance Icon"
                            title={
                              geolocationData?.status === "Dead"
                                ? "Dead : If declared dead"
                                : "Lost : If tiger has not been seen in the last 6 months."
                            }
                            style={{ width: "15.5px", marginLeft: "60px" }}
                          />
                        </h4>

                        <hr className="cardline" />
                      </div>
                      <div className="content">{geolocationData?.status}</div>
                    </div>
                  </div>
                </div>
                <div className="GeolocationDetails">
                  <div className="GeolocationHeader">
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <img
                        className="cardicon"
                        src={require("../Assets/Icons/location_2.png")}
                        alt="Distance Icon"
                        title=""
                        style={{ width: "25px", marginRight: "10px" }}
                      />
                      Geo location Details -{" "}
                      {tiger_id && origin !== "losttigers"
                        ? "(Current Location)"
                        : "(Last Known Location)"}
                    </span>
                  </div>
                  <div className="GeolocationDetailsEditContent">
                    <div className="datacard" style={{ width: "18%" }}>
                      <div className="cardheader">
                        <h4>Range</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">
                        {radio === "radio1"
                          ? "NA"
                          : radio === "radio2"
                          ? uploadedData[0].range
                          : geolocationData?.last_seen_data?.range_name}
                      </div>
                    </div>
                    <div className="datacard" style={{ width: "18%" }}>
                      <div className="cardheader">
                        <h4>Round</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">
                        {radio === "radio1"
                          ? "NA"
                          : radio === "radio2"
                          ? uploadedData[0].round
                          : geolocationData?.last_seen_data?.round_name}
                      </div>
                    </div>
                    <div className="datacard" style={{ width: "18%" }}>
                      <div className="cardheader">
                        <h4>Beat</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">
                        {radio === "radio1"
                          ? "NA"
                          : radio === "radio2"
                          ? uploadedData[0].beat
                          : geolocationData?.last_seen_data?.beat_name}
                      </div>
                    </div>
                    <div className="datacard" style={{ width: "18%" }}>
                      <div className="cardheader">
                        <h4>Compartment</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">
                        {radio === "radio1"
                          ? "NA"
                          : radio === "radio2"
                          ? uploadedData[0].compartment
                          : geolocationData?.last_seen_data?.compartment_name}
                      </div>
                    </div>
                    <div className="datacard" style={{ width: "18%" }}>
                      <div className="cardheader">
                        <h4>Station ID</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">
                        {radio === "radio1"
                          ? "NA"
                          : radio === "radio2"
                          ? uploadedData[0].station
                          : geolocationData?.last_seen_data?.station_name}
                      </div>
                    </div>
                    <div className="datacard" style={{ width: "18%" }}>
                      <div className="cardheader">
                        <h4>Camera ID</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">
                        {radio === "radio1"
                          ? "NA"
                          : radio === "radio2"
                          ? uploadedData[0].number
                          : geolocationData?.last_seen_data?.number_type}
                      </div>
                    </div>
                    <div className="datacard" style={{ width: "19%" }}>
                      <div className="cardheader">
                        <h4>Latitude</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">
                        {radio === "radio2"
                          ? uploadedData[0].latitude
                          : geolocationData?.last_seen_data?.latitude}
                      </div>
                    </div>
                    <div className="datacard" style={{ width: "19%" }}>
                      <div className="cardheader">
                        <h4>Longitude</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">
                        {radio === "radio2"
                          ? uploadedData[0].longitude
                          : geolocationData?.last_seen_data?.longitude}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="GeolocationDetails">
                  <div className="GeolocationHeader">
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <img
                        className="cardicon"
                        src={require("../Assets/Icons/add_info.png")}
                        alt="Distance Icon"
                        title=""
                        style={{ width: "25px", marginRight: "10px" }}
                      />
                      Additional Details
                    </span>
                  </div>
                  <div className="GeolocationDetailsEditContent">
                    <div className="datacard">
                      <div className="cardheader">
                        <h4>Distance Travelled: 3 Months</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">
                        {geolocationData?.distance_traveled_3m} Km
                      </div>
                    </div>
                    <div className="datacard">
                      <div className="cardheader">
                        <h4>Distance Travelled: 6 Months</h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">
                        {geolocationData?.distance_traveled_6m} Km
                      </div>
                    </div>
                    <div className="datacard">
                      <div className="cardheader" style={{ marginTop: "-3px" }}>
                        <h4>
                          Dist from Last Known Loc.
                          <img
                            style={{ width: "7%", marginLeft: "20px" }}
                            className="cardicon"
                            src={require("../Assets/Icons/i.png")}
                            alt="Distance Icon"
                            title="Distance from current location to last know location"
                          />
                        </h4>
                        <hr className="cardline" />
                      </div>
                      <div className="content">
                        {geolocationData?.last_seen_data?.distance
                          ? `${geolocationData.last_seen_data.distance} Km`
                          : "NA"}
                      </div>
                    </div>
                    <div className="datacard">
                      <div className="cardheader">
                        <h4>
                          Nearby Tigers
                          <img
                            style={{ width: "7%", marginLeft: "110px" }}
                            className="cardicon"
                            src={require("../Assets/Icons/i.png")}
                            alt="Distance Icon"
                            title=" Number of Tigers sighted within 5km near the current Tiger in last 1 month"
                          />
                        </h4>
                        <hr className="cardline" />
                      </div>
                      <div
                        className="content"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {nearestTigerCount}
                        {nearestTigerCount > prevTigerCount ? (
                          <img
                            style={{ width: "7%", marginLeft: "10px" }}
                            className="cardicon"
                            src={require("../Assets/Icons/increase.png")}
                            alt="Increase Icon"
                          />
                        ) : nearestTigerCount < prevTigerCount ? (
                          <img
                            style={{ width: "7%", marginLeft: "10px" }}
                            className="cardicon"
                            src={require("../Assets/Icons/decrease.png")}
                            alt="Decrease Icon"
                          />
                        ) : (
                          <img
                            style={{ width: "7%", marginLeft: "10px" }}
                            className="cardicon"
                            src={require("../Assets/Icons/equal.png")}
                            alt="Equal Icon"
                          />
                        )}
                      </div>
                    </div>
                    <div className="datacard">
                      <div className="cardheader">
                        <h4>
                          Herbivores Count
                          <img
                            style={{ width: "7%", marginLeft: "85px" }}
                            className="cardicon"
                            src={require("../Assets/Icons/i.png")}
                            alt="Distance Icon"
                            title=" Number of herbivorous sighed withing 5 km radius near the current Tiger in last 1 month. Herbivores List - [ Deer, Rabbit, Antelope, Wild boar, Indian Gour ]"
                          />
                        </h4>
                        <hr className="cardline" />
                      </div>
                      <div
                        className="content"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {herbCount}
                        {herbCount > prevHerbCount ? (
                          <img
                            style={{ width: "7%", marginLeft: "10px" }}
                            className="cardicon"
                            src={require("../Assets/Icons/increase.png")}
                            alt="Increase Icon"
                          />
                        ) : herbCount < prevHerbCount ? (
                          <img
                            style={{ width: "7%", marginLeft: "10px" }}
                            className="cardicon"
                            src={require("../Assets/Icons/decrease.png")}
                            alt="Decrease Icon"
                          />
                        ) : (
                          <img
                            style={{ width: "7%", marginLeft: "10px" }}
                            className="cardicon"
                            src={require("../Assets/Icons/equal.png")}
                            alt="Equal Icon"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        fontSize: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{ width: "4%", marginRight: "5px" }}
                        className="cardicon"
                        src={require("../Assets/Icons/increase.png")}
                        alt="Increase Icon"
                      />
                      Increase in count compared to the previous year.
                    </div>

                    <div
                      style={{
                        fontSize: "10px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "-180px",
                      }}
                    >
                      <img
                        style={{ width: "4%", marginRight: "5px" }}
                        className="cardicon"
                        src={require("../Assets/Icons/decrease.png")}
                        alt="Increase Icon"
                      />
                      Decrease in count compared to the previous year.
                    </div>

                    <div
                      style={{
                        fontSize: "10px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "-180px",
                      }}
                    >
                      <img
                        style={{ width: "4%", marginRight: "5px" }}
                        className="cardicon"
                        src={require("../Assets/Icons/equal.png")}
                        alt="Increase Icon"
                      />
                      No change in count compared to the previous year
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="SpeciesTable">
            {selectedSpecies && (
              <TigerTable
                speciesID={[]}
                geolocationBeat={[]}
                tiger_types={selectedSpecies}
                latitude={
                  currentLatitude ||
                  (markers.length >= 2 ? markers[0].lat : null)
                }
                longitude={
                  currentLongitude ||
                  (markers.length >= 2 ? markers[0].lng : null)
                }
                onTigerTableData={getZone}
                type={props.tiger_id ? "upload_flow" : null}
              />
            )}
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default TigerView;
