import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StatsBar from "../StatsBar";
import { useContext } from "react";
import TadobaContext from "../Context/TadobaContext";
import { Select } from "antd";
import LazyImage from "../LazyImage";
import { LoadingOutlined } from "@ant-design/icons";
import { makeRequest } from "../../api";
import Popup from "reactjs-popup";
import leftArrowIcon from "../../Assets/Icons/left_arrow.png";
import rightArrowIcon from "../../Assets/Icons/right_arrow.png";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { warningToaster } from "../../Data/Data";
import { IoCloseSharp } from "react-icons/io5";
import { GrRevert } from "react-icons/gr";

const ImageEdit = (props) => {
  const api = useContext(TadobaContext);
  // const { image_id } = useParams();
  const image_id = props.id;
  const [render, setRender] = useState();
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [selectedSpecies, setSelectedSpecies] = useState(null);
  const [edit, setEdit] = useState(false);
  const [species, setSpecies] = useState([]);
  const [classified, setClassified] = useState([]);
  const [loading, setLoading] = useState(false);
  const [speciestype, setSpeciestype] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tigerData, setTigerData] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedTiger, setSelectedTiger] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedTigerIndex, setSelectedTigerIndex] = useState(0);
  const [activeButton, setActiveButton] = useState(
    tigerData.length > 0 ? tigerData[0].name : null
  );
  const [popupLoading, setPopupLoading] = useState(false);
  const [tigerslist, setTigersList] = useState([]);
  const [chosenTiger, setChosenTiger] = useState(null);
  const [chosenTigerId, setChosenTigerId] = useState(null);
  const [allTigerData, setAllTigerData] = useState([]);

  useEffect(() => {
    getImages();
  }, [render]);

  const getImages = async () => {
    let token = "";
    setLoading(true);
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/classification/image_get/?image_id=${image_id}`
      );

      setData(result.data.data);
      const res = result.data.data;
      setSpeciestype(res.species.name);
      setChosenTiger(res.specie_sub_type?.name);
      setLoading(false);
      setEdit(false);
    } catch (error) {
      setLoading(false);
      setEdit(false);
    }
  };
  useEffect(() => {
    if (api.allSpecies !== null) {
      setSpecies(
        api.allSpecies.map((value, key) => {
          return {
            label: value.name,
            value: key,
            key: value.id,
          };
        })
      );
      if (data !== null && species?.length !== 0 && data?.species !== null) {
        setSelectedSpecies(
          species.filter((value) => value.label === data.species?.name)[0].value
        );
      }
    } else {
      setSelectedSpecies(null);
      setSpecies(null);
    }
  }, [api.allSpecies, data]);

  const saveHandler = async () => {
    if (chosenTigerId) {
      identifyHandler_2(chosenTigerId);
    } else {
      setLoading(true);
      let token = "";
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }
        const result = await makeRequest(
          "post",
          `api/v1/classification/classify_species_manually/`,
          { data: classified }
        );
        setRender(render + 1);
      } catch (error) {
        setLoading(false);
      }
    }
  };
  const identifyHandler = async () => {
    setLoading(true);
    let token = "";
    try {
      const data = new FormData();
      data.append("image_id", image_id);
      data.append("tiger_id", tigerData[selectedTigerIndex].id);
      data.append("origin", "upload");
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "post",
        `api/v1/identification/tiger_identify`,
        data
      );
      setChosenTiger(tigerData[selectedTigerIndex].name);
      setLoading(false);
      setIsOpen(false);
      setPopupLoading(false);
      setEdit(false);
      getImages();
    } catch (error) {
      setLoading(false);
    }
  };

  const identifyHandler_2 = async (tiger_id) => {
    setLoading(true);
    let token = "";
    try {
      const data = new FormData();
      data.append("image_id", image_id);
      data.append("tiger_id", tiger_id);
      data.append("origin", "upload");
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "post",
        `api/v1/identification/tiger_identify`,
        data
      );
      setLoading(false);
      setIsOpen(false);
      setPopupLoading(false);
      setEdit(false);
      getImages();
    } catch (error) {
      setLoading(false);
    }
  };
  const viewDate = (dateString) => {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1; // Months are zero-based
    const day = dateObject.getDate();
    const simpleDateString = `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;

    return simpleDateString;
  };

  const editHandler = () => {
    setEdit(true);
  };

  const speciesChangeHandler = (event) => {
    const species_id = species.filter((value) => value.value === event)[0].key;
    setClassified([
      {
        image_id: image_id,
        species_type_id: species_id,
      },
    ]);
    setSelectedSpecies(event);
  };

  const tigerChangeHandler = (event) => {
    const name = getNameById(event);
    setChosenTigerId(event);
    setChosenTiger(name);
  };

  function getNameById(id) {
    const foundObject = allTigerData.find((item) => item.id === id);
    if (foundObject) {
      return foundObject.name;
    } else {
      return null;
    }
  }
  const fetchAdditionalImages = async (image_id) => {
    setPopupLoading(true);
    setActiveButton(false);
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const data = new FormData();
      data.append("id", image_id);
      data.append("origin", "upload");
      const response = await makeRequest(
        "post",
        `api/v1/identification/tiger_images`,
        data
      );

      const namesArray = response.data.data.map((item) => item.name);
      if (namesArray.length > 0) {
        setIsOpen(true);
      }
      setPopupLoading(false);
      setOptions(namesArray);
      setTigerData(response.data.data);
    } catch (error) {
      setPopupLoading(false);
      setIsOpen(false);
      toast.success(
        "Unable to identify tiger. Please click on edit and manually select Tiger name.",
        warningToaster
      );
    }
  };

  useEffect(() => {
    const fetchTiger = async () => {
      let token = "";
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }

        const result = await makeRequest(
          "get",
          "api/v1/identification/species_tiger/"
        );
        const result_data = result.data.data;
        setAllTigerData(result_data);

        const names = result_data
          .map((tiger) => {
            return {
              value: tiger.id,
              label: tiger.name,
            };
          })
          .filter(Boolean);

        setTigersList(names);
      } catch (error) {
        toast.success("Error While getting Tiger data", warningToaster);
      }
    };
    fetchTiger();
  }, []);

  // useEffect(() => {
  //   fetchAdditionalImages();
  // }, []);

  const handleLeftArrowClick = () => {
    const imagesCount = tigerData[selectedTigerIndex]?.images?.length || 0;
    if (imagesCount > 0) {
      setSelectedImageIndex((prevIndex) => {
        if (prevIndex > 0) {
          return prevIndex - 1;
        } else {
          return imagesCount - 1;
        }
      });
    }
  };

  const handleRightArrowClick = () => {
    const imagesCount = tigerData[selectedTigerIndex]?.images?.length || 0;
    if (imagesCount > 0) {
      setSelectedImageIndex((prevIndex) => {
        if (prevIndex < imagesCount - 1) {
          return prevIndex + 1;
        } else {
          return 0;
        }
      });
    }
  };

  const handleNameClick = (selectedOption) => {
    const flag = selectedOption === activeButton ? null : selectedOption;
    setActiveButton(flag);
    const selectedTigerIndex = tigerData.findIndex(
      (tiger) => tiger.name === selectedOption
    );
    if (selectedTigerIndex !== -1) {
      setSelectedTigerIndex(selectedTigerIndex);
      setSelectedImageIndex(0);
    }
  };

  useEffect(() => {
    if (tigerData.length > 0) {
      setSelectedTiger(
        tigerData[selectedTigerIndex]?.images[selectedImageIndex]?.image_key ||
          ""
      );
    }
  }, [tigerData, selectedImageIndex, selectedTigerIndex]);

  useEffect(() => {
    const selectedOption = tigerData[selectedTigerIndex]?.name;
    const flag = selectedOption === activeButton ? null : selectedOption;
    if (tigerData.length > 0) {
      setActiveButton(flag);
    }
  }, [tigerData]);

  const handleCancel = () => {
    props.closeEditImgPopup();
  }

  const revertTigerIdentification = async () => {
    let token = "";
    setLoading(true);
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "put",
        "api/v1/identification/revert_tiger_identification/",
        { id: data.id },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
        }
      );
      setLoading(false);
      props.closeEditImgPopup();
      if (props.revertTiger) {
        const filteredItems = props.listData.filter(
          (item) => item.id !== data.id
        );
        props.setListData(filteredItems);
        toast.success(`Successfully Unidentified Image`, {
          ...warningToaster,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.success(`Unidentification Unsuccessful`, {
        ...warningToaster,
        autoClose: 2000,
      });
      console.error("Error reverting tiger identification:", error);
      setLoading(false);
      props.closeEditImgPopup();
    }
  };

  if (!loading)
    return (
      <div style={{ width: "80vw" }}>
        {popupLoading ? (
          <div className="loading-mask">
            <div style={{ textAlign: "center" }}>
              <ClipLoader
                color={"green"}
                loading={popupLoading}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <p style={{ marginTop: "10px", color: "green" }}>
                Loading Matching Tigers...
              </p>
            </div>
          </div>
        ) : (
          <div>
            {/* <div className="EditHeadBar">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props.setEdit(false);
                  navigate("/images/");
                }}
              >
                All images
              </span>
              <img src={require("../../Assets/Icons/right_icon.png")} alt="" />
              <span>{image_id}.png</span>
            </div> */}
            <div className="Setting_User_Header">
              <div style={{ paddingLeft: "2vw" }}>
                <span>{image_id}.png</span>
              </div>
              {!edit ? (
                <div
                  className="Setting_User_Attribute"
                  style={{ cursor: "no-drop" }}
                >
                  {speciestype === "Tiger" && !data.specie_sub_type?.name && (
                    <Popup
                      open={isOpen}
                      trigger={
                        <div className="Setting_Add_Button">
                          <img
                            src={require("../../Assets/Icons/tigericon.png")}
                            alt=""
                          />
                          <span onClick={() => fetchAdditionalImages(image_id)}>
                            Identify tiger
                          </span>
                        </div>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div
                          className="PopupEditRole"
                          style={{ width: "1200px" }}
                        >
                          <div className="popupEditContent">
                            <div className="body">
                              <div
                                className="half"
                                style={{ width: "45%", marginLeft: "30px" }}
                              >
                                <span>Uploaded Image</span>
                                <LazyImage src={data?.image} alt="image 1" />
                              </div>
                              <div
                                className="half"
                                style={{ width: "45%", marginRight: "30px" }}
                              >
                                <span>
                                  Matching Tigers (
                                  {tigerData[selectedTigerIndex]?.name})
                                </span>
                                {tigerData.length > 0 && (
                                  <LazyImage
                                    src={selectedTiger}
                                    loading="lazy"
                                    alt="Tiger image"
                                  />
                                )}

                                {tigerData[selectedTigerIndex]?.images.length >
                                  0 && (
                                  <>
                                    <img
                                      src={leftArrowIcon}
                                      alt="Left Arrow"
                                      className="arrow left-arrow"
                                      onClick={handleLeftArrowClick}
                                    />
                                    <img
                                      src={rightArrowIcon}
                                      alt="Right Arrow"
                                      className="arrow right-arrow"
                                      onClick={handleRightArrowClick}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "30%",
                                display: "flex",
                                justifyContent: "space-between",
                                marginLeft: "450px",
                              }}
                            >
                              <div style={{ flex: 1, marginTop: "-20px" }}>
                                {tigerData.map((tiger, index) => (
                                  <StatsBar
                                    key={tiger.name}
                                    score={tiger.matching_score * 100}
                                    name={
                                      <button
                                        key={index}
                                        className={`Setting_Select_Button ${
                                          tiger.name === activeButton
                                            ? "active"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handleNameClick(tiger.name)
                                        }
                                        style={{ minWidth: "75px" }}
                                      >
                                        {tiger.name}
                                      </button>
                                    }
                                    isActive={tiger.name === activeButton}
                                  />
                                ))}
                              </div>
                            </div>

                            <div className="dropdownContainer">
                              <button
                                className="DeleteButton"
                                onClick={() => {
                                  identifyHandler();
                                  close();
                                }}
                                style={{
                                  marginLeft: "950px",
                                  marginTop: "-200px",
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Popup>
                  )}
                  {/* <img
                    src={require("../../Assets/Icons/edit_fav.png")}
                    style={{ cursor: "no-drop" }}
                    alt=""
                  />
                  <img
                    src={require("../../Assets/Icons/edit_share.png")}
                    style={{ cursor: "no-drop" }}
                    alt=""
                  />
                  <img
                    src={require("../../Assets/Icons/edit_download.png")}
                    style={{ cursor: "no-drop" }}
                    alt=""
                  /> */}

                  {api.user.access.images === 3 ? (
                    <>
                      {/* <img
                        src={require("../../Assets/Icons/edit_trash.png")}
                        style={{ cursor: "no-drop" }}
                        alt=""
                      /> */}
                      {api.user.role.id === 2 || api.user.role.id === 1 ? (
                        <div
                          style={{
                            display: "flex",
                            gap: "1vw",
                            alignItems: "center",
                          }}
                        >
                          {props.revertTiger && (
                            <div
                              onClick={revertTigerIdentification}
                              className="revert_button"
                            >
                              <GrRevert />
                              <span> Unidentify Image</span>
                            </div>
                          )}

                          <div
                            className="edit_button atom-animation"
                            onClick={editHandler}
                          >
                            <img
                              src={require("../../Assets/Icons/edit_pencil.png")}
                              alt=""
                            />
                            <span>Edit</span>
                          </div>
                          <div
                            style={{
                              fontSize: "4vh",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={handleCancel}
                          >
                            <IoCloseSharp />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="popupDeleteButtonUsers">
                  <button
                    className="CancelButton atom-animation"
                    onClick={() => {
                      setEdit(false);
                      setChosenTiger(null);
                      setChosenTigerId(null);
                    }}
                  >
                    Cancel
                  </button>

                  <button
                    onClick={() => saveHandler()}
                    disabled={classified?.length === 0 && !chosenTigerId}
                    className={
                      classified?.length !== 0 || chosenTigerId
                        ? "DeleteButton atom-animation"
                        : "disableddelete atom-animation"
                    }
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <div className="Image_Edit_Mode">
              <div className="Image_Edit_View">
                <LazyImage
                  src={data?.image}
                  placeholderSrc={require("../../Assets/Icons/temp_img.png")}
                  loading="lazy"
                  alt=""
                />
              </div>
              <div className="Image_Edit_Data">
                <div className="BasicDetails">
                  <div className="BasicHeader">
                    <span>Basic details</span>
                  </div>
                  <div className="GeolocationDetailsEditContent">
                    {/* <div className="BasicDetailsEditContent"> */}
                    {edit === true && data?.species?.name !== "Tiger" && (
                      <div
                        className="DirectionDropdown"
                        style={{ width: "100%" }}
                      >
                        <label htmlFor="">Species</label>
                        <Select
                          showSearch
                          style={{ width: 200 }}
                          placeholder="Select"
                          optionFilterProp="children"
                          loading={species !== null && species?.length === 0}
                          size={"large"}
                          value={selectedSpecies}
                          onChange={(data) => {
                            speciesChangeHandler(data);
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").includes(input)
                          }
                          options={species}
                        />
                      </div>
                    )}
                    {edit === false && (
                      <div className="BasicDetailEditField">
                        <label htmlFor="Range no.">Species category</label>
                        <span>{data?.species?.name}</span>
                      </div>
                    )}
                    <div className="BasicDetailEditField">
                      <label htmlFor="Range no.">Date</label>
                      <span>{viewDate(data?.captured_at)}</span>
                    </div>
                    {data?.species?.name === "Tiger" ? (
                      edit ? (
                        <div
                          className="DirectionDropdown"
                          style={{ width: "100%" }}
                        >
                          <label htmlFor="">Tiger Name</label>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Select"
                            optionFilterProp="children"
                            size={"large"}
                            value={chosenTiger}
                            onChange={(data) => {
                              tigerChangeHandler(data);
                            }}
                            filterOption={(input, option) =>
                              (option?.label ?? "").includes(input)
                            }
                            options={tigerslist}
                          />
                        </div>
                      ) : (
                        <div className="BasicDetailEditField">
                          <label htmlFor="Range no.">Tiger Name</label>
                          <span>{chosenTiger}</span>
                        </div>
                      )
                    ) : null}
                    {data?.species?.name === "Tiger" ? (
                      <div className="BasicDetailEditField">
                        <label htmlFor="Range no.">Tiger Age</label>
                        <span>{data?.specie_sub_type?.age?.name}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {data?.species?.name === "Tiger" ? (
                      <div className="BasicDetailEditField">
                        <label htmlFor="Range no.">Tiger Gender</label>
                        <span>{data?.specie_sub_type?.gender?.name}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="GeolocationDetails">
                  <div className="GeolocationHeader">
                    <span>Geo location details</span>
                  </div>
                  <div className="GeolocationDetailsEditContent">
                    <div className="BasicDetailEditField">
                      <label htmlFor="Range no.">Range no.</label>
                      <span>{data?.range.name}</span>
                    </div>
                    <div className="BasicDetailEditField">
                      <label htmlFor="Range no.">Round no.</label>
                      <span>{data?.round.name}</span>
                    </div>
                    <div className="BasicDetailEditField">
                      <label htmlFor="Range no.">Beat no.</label>
                      <span>{data?.beat.name}</span>
                    </div>
                    <div className="BasicDetailEditField">
                      <label htmlFor="Range no.">Compartment no.</label>
                      <span>{data?.compartment.name}</span>
                    </div>
                    <div className="BasicDetailEditField">
                      <label htmlFor="Range no.">Station ID</label>
                      <span>{data?.station.name}</span>
                    </div>
                    <div className="BasicDetailEditField">
                      <label htmlFor="Range no.">Camera ID</label>
                      <span>{data?.camera.name}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  else {
    return (
      <div className="spinner">
        <LoadingOutlined
          style={{
            fontSize: 100,
            color: "green",
            fill: "green",
          }}
          spin
        />
      </div>
    );
  }
};

export default ImageEdit;
