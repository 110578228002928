import React, { useState, useContext, useEffect } from "react";
import { MapKey, mapStyles } from "../Data/Data";
import {
  GoogleMap,
  LoadScript,
  Polygon,
  InfoWindow,
  OverlayView,
} from "@react-google-maps/api";
import TadobaContext from "../Components/Context/TadobaContext";
import { makeRequest } from "../api";
import { ConfigProvider, Select, Space } from "antd";
import * as turf from "@turf/turf";

const PolygonView = ({ tigerslist, mapRef, setMapRef, selectedYear }) => {
  const api = useContext(TadobaContext);
  const [position, setPosition] = useState(null);
  const [selectedGender, setSelectedGender] = useState("all");
  const [activePolygon, setActivePolygon] = useState(null); // Track which polygon is clicked
  const [polygonData, setPolygonData] = useState([]);
  const [filteredPolygons, setFilteredPolygons] = useState([]); // State for filtered polygons
  const [loading, setLoading] = useState(false);
  const [selectedTiger, setSelectedTiger] = useState([]);

  useEffect(() => {
    const fetchPolygon = async () => {
      setLoading(true);
      let token = "";
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }
        const result = await makeRequest(
          "get",
          `api/v1/identification/tiger_path_plot/?gender=${
            selectedGender === "all" ? "" : selectedGender
          }&year=${selectedYear}`
        );
        if (result && result.data && result.data.data.length > 0) {
          const transformed_data = transformData(result.data.data);
          setPolygonData(transformed_data);

          if (selectedTiger.length > 0) {
            const selectedLabels = selectedTiger
              .map((val) => {
                const tiger = tigerslist.find((tiger) => tiger.value === val);
                return tiger ? tiger.label : null;
              })
              .filter(Boolean);

            const filtered = transformed_data.filter((polygon) =>
              selectedLabels.includes(polygon.name)
            );
            setFilteredPolygons(filtered);
          } else {
            setFilteredPolygons(transformed_data);
          }
        } else {
          console.warn(
            "No data available for the selected year:",
            selectedYear
          );
          setPolygonData([]);
          setFilteredPolygons([]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching polygon data:", error);
        setLoading(false);
      }
    };
    fetchPolygon();
  }, [selectedGender, selectedYear, selectedTiger]);

  const transformData = (data) => {
    return data
      .map((item) => {
        const coordinates = item.images
          .map((image) => {
            const lng = parseFloat(image.longitude);
            const lat = parseFloat(image.latitude);

            if (!isNaN(lng) && !isNaN(lat)) {
              return [lng, lat];
            } else {
              console.warn(
                `Invalid coordinates for image of tiger ${item.name}: `,
                image
              );
              return null;
            }
          })
          .filter((coord) => coord !== null);

        if (coordinates.length === 0) {
          console.warn(`No valid coordinates for tiger ${item.name}`);
          return null;
        }

        let simplifiedCoordinates;
        if (coordinates.length === 2) {
          // Special case for two points: just draw a line between them
          simplifiedCoordinates = coordinates.map(([lng, lat]) => ({
            lat,
            lng,
          }));
        } else {
          // Use convex hull for more than two points
          const points = turf.points(coordinates);
          const hull = turf.convex(points);

          simplifiedCoordinates = hull
            ? hull.geometry.coordinates[0].map(([lng, lat]) => ({ lat, lng }))
            : [];
        }

        const polygonCenter =
          simplifiedCoordinates.length > 0
            ? simplifiedCoordinates.reduce(
                (acc, { lat, lng }) => ({
                  lat: acc.lat + lat / simplifiedCoordinates.length,
                  lng: acc.lng + lng / simplifiedCoordinates.length,
                }),
                { lat: 0, lng: 0 }
              )
            : { lat: coordinates[0][1], lng: coordinates[0][0] };

        return {
          name: item.name,
          species_type: item.species_type,
          species_age: item.species_age,
          species_gender: item.species_gender,
          species_status: item.species_status,
          no_of_images: item.no_of_images,
          thumbnail: item.images.length > 0 ? item.images[0].image_key : null,
          coordinates: simplifiedCoordinates,
          center: polygonCenter,
          color: generateRandomColor(),
        };
      })
      .filter((polygon) => polygon !== null);
  };

  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const onLoad = (map) => {
    setMapRef(map);
  };

  const handleMapLoad = (map) => {
    setPosition({
      lat: map.latLng.lat(),
      lng: map.latLng.lng(),
    });
  };

  const handleDragEnd = () => {
    if (mapRef) {
      const newCenter = mapRef.getCenter();
      setPosition({
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      });
    }
  };

  const handlePolygonClick = (polygon) => {
    setActivePolygon(polygon); // Set the clicked polygon as active
  };

  const handleInfoWindowClose = () => {
    setActivePolygon(null); // Close InfoWindow
  };

  const handleGenderChange = (e) => {
    setSelectedTiger([]);
    setSelectedGender(e.target.value);
  };

  const tigerSelectionHandler = (selectedValues) => {
    setSelectedTiger(selectedValues);

    const selectedLabels = selectedValues
      .map((val) => {
        const tiger = tigerslist.find((tiger) => tiger.value === val);
        return tiger ? tiger.label : null;
      })
      .filter(Boolean);

    if (selectedLabels.length > 0) {
      const filtered = polygonData.filter((polygon) =>
        selectedLabels.includes(polygon.name)
      );
      setFilteredPolygons(filtered);
    } else {
      setFilteredPolygons(polygonData);
    }
  };

  const getFilteredTigerList = () => {
    if (selectedGender === "all") {
      return tigerslist;
    }
    return tigerslist.filter((tiger) =>
      selectedGender === "male"
        ? tiger.label.endsWith("-M")
        : tiger.label.endsWith("-F")
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            buttonSolidCheckedBg: "#E8DFD5",
            buttonSolidCheckedActiveBg: "#E8DFD5",
            buttonSolidCheckedHoverBg: "#E8DFD5",
            colorPrimary: "#FFF3E8",
            colorPrimaryHover: "#FFF3E8",
            colorPrimaryBorder: "#FFF3E8",
          },
          DatePicker: {
            activeBorderColor: "#FFF3E8",
            colorLinkActive: "#FFF3E8",
            colorPrimary: "#FFF3E8",
            hoverBorderColor: "#FFF3E8",
            colorPrimaryBorder: "#FFF3E8",
          },
          Select: {
            optionActiveBg: "#FFF3E8",
            optionSelectedBg: "#FFF3E8",
            optionFontSize: 14,
            colorPrimaryHover: "#E67817",
            controlOutline: "#FFF3E8",
            multipleItemBg: "#FFF3E8",
          },
        },
      }}
    >
      <div className="">
        <div style={{ paddingBottom: "0px" }} className="Card MapCard">
          <div className="Polygon-View">
            <LoadScript googleMapsApiKey={MapKey}>
              <GoogleMap
                mapContainerStyle={{ width: "95%", height: "100%" }}
                center={
                  position === null
                    ? { lat: 11.962482, lng: 77.15502 }
                    : position
                }
                zoom={10}
                onClick={handleMapLoad}
                onLoad={onLoad}
                onDragEnd={handleDragEnd}
                options={{
                  styles: mapStyles,
                }}
              >
                {/* Main Polygon */}
                <Polygon
                  paths={api.polygonCoordinates}
                  options={{
                    fillColor: "#848482",
                    fillOpacity: 0.3,
                    strokeColor: "#36454F",
                    strokeOpacity: 0.6,
                    strokeWeight: 2,
                  }}
                  onClick={handleMapLoad}
                />
                {filteredPolygons?.map((polygon, index) => (
                  <React.Fragment key={index}>
                    {/* Render Polygon */}
                    <Polygon
                      paths={polygon.coordinates}
                      options={{
                        fillOpacity: 0,
                        strokeColor: polygon.color,
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                      onClick={() => handlePolygonClick(polygon)}
                    />

                    {/* Render Tiger Name at the Center of Polygon using OverlayView */}
                    <OverlayView
                      position={polygon.center}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                      <div
                        style={{
                          // background: "white",
                          padding: "2px 5px",
                          borderRadius: "5px",
                          fontWeight: "bold",
                          // color: polygon.color,
                          // boxShadow: "0px 0px 2px rgba(0,0,0,0.3)",
                          textAlign: "center",
                        }}
                      >
                        {polygon.name}
                      </div>
                    </OverlayView>
                  </React.Fragment>
                ))}

                {/* Show InfoWindow when a polygon is clicked */}
                {activePolygon && (
                  <InfoWindow
                    position={activePolygon.coordinates[0]}
                    onCloseClick={handleInfoWindowClose}
                  >
                    <div style={{ margin: "1vh 2vw 1vh 2vw" }}>
                      <h4>Name: {activePolygon.name}</h4>
                      <h5>Gender: {activePolygon.species_gender}</h5>
                      <h5>Age: {activePolygon.species_age}</h5>
                      <h5>Status: {activePolygon.species_status}</h5>
                    </div>
                  </InfoWindow>
                )}
              </GoogleMap>
            </LoadScript>
          </div>
          <div className="Map-Right">
            <div className="Map-View-Description">
              <span>
                View the area covered by various Tigers, filter according to
                gender or name
              </span>
            </div>
            <div className="gender-filter">
              <label>
                <input
                  type="radio"
                  value="all"
                  checked={selectedGender === "all"}
                  onChange={handleGenderChange}
                />
                All
              </label>
              <label>
                <input
                  type="radio"
                  value="male"
                  checked={selectedGender === "male"}
                  onChange={handleGenderChange}
                />
                Male
              </label>
              <label>
                <input
                  type="radio"
                  value="female"
                  checked={selectedGender === "female"}
                  onChange={handleGenderChange}
                />
                Female
              </label>
            </div>
            <div className="filter-options">
              <div className="DirectionDropdown" style={{ width: "100%" }}>
                <label htmlFor="">Tiger Name</label>
                <Select
                  showSearch
                  mode="multiple"
                  style={{ width: 400 }}
                  placeholder="Select"
                  optionFilterProp="children"
                  size={"large"}
                  value={selectedTiger}
                  onChange={(value) => tigerSelectionHandler(value)} // Only pass the selected values (value)
                  allowClear={true}
                  loading={
                    (tigerslist !== null && tigerslist.length === 0) || loading
                  }
                  options={getFilteredTigerList()} // tigerslist contains the options with value and label
                />
              </div>
            </div>
            <div className="polygon-species-names">
              {getFilteredTigerList()?.map((item) => {
                const tigerPolygon = polygonData.find(
                  (polygon) => polygon.name === item.label
                );

                return (
                  <div
                    key={item.value}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <div
                      style={{
                        width: "2vw",
                        height: "2vh",
                        borderColor: tigerPolygon ? tigerPolygon.color : "#000",
                        border: "3px solid",
                        marginRight: "0.5vw",
                        borderRadius: "4px",
                      }}
                    ></div>
                    <span style={{ fontSize: "0.8rem" }}>{item.label}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default PolygonView;
