import React, { useEffect } from "react";
import { useState, CSSProperties } from "react";
import UploadLoader from "./UploadLoader";
import axios from "axios";
import { url } from "../../Data/Data";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import TadobaContext from "../Context/TadobaContext";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { GoogleMap, Marker, LoadScript, Polygon } from "@react-google-maps/api";
import { warningToaster, successToaster } from "../../Data/Data";
import { makeRequest } from "../../api";
import LazyImage from "../LazyImage";
import leftArrowIcon from "../../Assets/Icons/left_arrow.png";
import rightArrowIcon from "../../Assets/Icons/right_arrow.png";
import Tiger_routes from "./Tiger_routes";
import StatsBar from "../StatsBar";
import ClipLoader from "react-spinners/ClipLoader";
import Popup from "reactjs-popup";
import BackButton from "../BackButton";

const UploadTiger = ({ file, setFile, setButtonClicked, buttonClicked }) => {
  const navigate = useNavigate();
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [location_type, setLocationType] = useState("");
  const [error, setError] = useState(false);
  const [number, setNumber] = useState("");
  const [range, setRange] = useState([]);
  const [camera, setCamera] = useState([]);
  const [cameraId, setCameraId] = useState("");
  const [position, setPosition] = useState(null);
  const tiger_api = useContext(TadobaContext);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(null);
  const [totalUpload, setTotalUpload] = useState(0);
  const [completeUpload, setCompleteUpload] = useState(0);
  const [tigerData, setTigerData] = useState([]);
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [selectedTiger, setSelectedTiger] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedTigerIndex, setSelectedTigerIndex] = useState(0);
  const [uploadedData, setUploadedData] = useState(null);
  const [identifyButtonClicked, setIdentifyButtonClicked] = useState(false);
  const [tigerId, setTigerId] = useState(null);
  const [selectedRadio, setSelectedRadio] = useState("radio3");
  const [classifiedSpecies, setClassifiedSpecies] = useState(null);
  const [activeButton, setActiveButton] = useState(
    tigerData.length > 0 ? tigerData[0].name : null
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const generateRandomKey = () => {
    const length = 6;
    let key = "";
    const characters = "0123456789";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      const randomChar = characters.charAt(randomIndex);
      key += randomChar;
    }

    return key;
  };

  const uploadAllImages = (imageFiles) => {
    const batchSize = 50;
    const batches = [];
    let images_data = [];
    let totalUploaded = 0;
    let randomKey = generateRandomKey();
    let totalUploadedSize = 0;
    let fileList = imageFiles;
    for (let i = 0; i < fileList.length; i++) {
      const url = fileList[i];
      totalUploadedSize += url.size;
    }

    tiger_api.setProgress({
      ...tiger_api.progress,
      totalUpload: totalUploadedSize,
    });
    setTotalUpload(totalUploadedSize);
    localStorage.setItem("upload_token", randomKey);
    tiger_api.setUploadKey(randomKey);

    for (let i = 0; i < imageFiles.length; i += batchSize) {
      const batch = imageFiles.slice(i, i + batchSize);
      batches.push(batch);
    }

    const processBatch = async (count, list) => {
      let shift = 0;
      if (batches.length === 0) {
        tiger_api.setImages(list);
        tiger_api.setUpload(false);
        navigate("./");
        tiger_api.setProgress({
          uploadPercentage: 0,
          totalUpload: 0,
          completeUpload: 0,
        });
        return true;
      }

      const batch = batches.shift();
      let formData = new FormData();
      formData.append("lat", latitude);
      formData.append("long", longitude);
      formData.append("upload_token", localStorage.getItem("upload_token"));
      formData.append("location_type", location_type);
      formData.append("number_type", cameraId);
      for (const key of Object.keys(batch)) {
        formData.append(
          "files",
          new File(
            [batch[key], "extra data: " + batch[key].path],
            batch[key].name,
            { type: batch[key].type }
          )
        );
      }

      let token = "";
      let csrftoken = "";
      tiger_api.setUpload(true);
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }
        if (localStorage.csrf) {
          csrftoken = localStorage.csrf;
        }
        console.log(formData);

        let pathname = "/image_upload_single";

        const result = await axios({
          url: `${url}api/v1/classification${pathname}`,
          withCredentials: true,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
            TADOBAAUTH: token,
            "X-CSRFToken": csrftoken,
          },
          data: formData,
          onUploadProgress: (progressEvent) => {
            let { loaded, total } = progressEvent;
            shift = total;
            let percent = Math.floor(
              ((count + loaded) / totalUploadedSize) * 100
            );
            tiger_api.setProgress({
              totalUpload: totalUploadedSize,
              uploadPercentage: percent,
              completeUpload: count + loaded,
            });
          },
        });
        if (result.data.data && result.data.data.length > 0) {
          setUploadedData(result.data.data);
          setClassifiedSpecies(result.data.data[0].species);
          console.log(result.data.data[0].species);
          if (result.data.data[0].species !== "Tiger") {
            toast.success(
              "Uploaded image is not of a tiger. Please try again with tiger image.",
              warningToaster
            );
            setCompleteUpload(0);
          } else {
            fetchAdditionalImages(result.data.data[0].id);
            setCompleteUpload(1);
            setLoading(true);
            list = [...list, ...result.data.data];
          }
        }
      } catch (error) {
        tiger_api.setUpload(false);
        setCamera([]);
        setNumber("");
        setNumber("");
        setLongitude("");
        setLatitude("");
        setPosition(null);
        setError(true);
        const message = error.response.data.data;
        toast.success(message, warningToaster);
        return false;
      }
      processBatch(count + shift, list);
    };
    processBatch(totalUploaded, images_data);
  };

  const identifyHandler = async () => {
    setLoading(false);
    let token = "";
    try {
      const data = new FormData();
      data.append("image_id", uploadedData[0].id);
      data.append("tiger_id", tigerData[selectedTigerIndex].id);
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "post",
        `api/v1/identification/tiger_identify`,
        data
      );

      setIdentifyButtonClicked(true);
      setTigerId(tigerData[selectedTigerIndex].id);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchPolygon = async () => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest("get", `api/v1/map/map_borders/`);
      const convertedData = result.data.data.map(({ latitude, longitude }) => ({
        lat: latitude,
        lng: longitude,
      }));
      setPolygonCoordinates(convertedData);
    } catch (error) {}
  };

  useEffect(() => {
    fetchPolygon();
  }, []);

  const numberChangeHandler = (event) => {
    const item = JSON.parse(event.target.value);
    setCameraId(item.name);
    setNumber(event.target.value);
    setLatitude(item.latitude.toFixed(6));
    setLongitude(item.longitude.toFixed(6));
    setPosition({
      lat: item.latitude,
      lng: item.longitude,
    });
    setError(false);
  };

  const locationChangeHandler = (event) => {
    setNumber("");
    setLocationType(event.target.value);
    const array = range.filter((val) => {
      return val.name === event.target.value;
    });
    fetchCameras(array[0].id);
  };

  const fetchCameras = async (id) => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const result = await makeRequest(
        "get",
        `api/v1/map/latlong_list/?range_id=${id}`
      );
      setCamera(result.data.data);
    } catch (error) {}
  };

  const fetchAdditionalImages = async (id) => {
    let token = "";
    try {
      if (localStorage.token) {
        token = localStorage.token;
      }
      const data = new FormData();
      data.append("id", id);
      const response = await makeRequest(
        "post",
        `api/v1/identification/tiger_images`,
        data
      );
      const namesArray = response.data.data.map((item) => item.name);
      setOptions(namesArray);
      setTigerData(response.data.data);
      if (response.data.data.length === 0) {
        setFile([]);
        toast.success("Unable to Identify the Uploaded Tiger", warningToaster);
      }
      if (response.data.data.length > 0) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setFile([]);
      console.error("Error fetching additional images:", error);
      toast.success(
        "Unable to Identify the Uploaded Tiger - " + error.response.data.data,
        warningToaster
      );
    }
  };

  const uploadHandler = () => {
    uploadAllImages(file);
  };

  const cancelHandler = () => {
    setFile([]);
  };

  useEffect(() => {
    const fetchRanges = async () => {
      let token = "";
      try {
        if (localStorage.token) {
          token = localStorage.token;
        }
        const result = await makeRequest("get", `api/v1/map/range_list/`);
        setRange(result.data.data);
      } catch (error) {}
    };
    fetchRanges();
  }, []);

  const handleMarkerDragEnd = (e) => {
    setLatitude(e.latLng.lat());
    setLongitude(e.latLng.lng());
    setPosition({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const handleLeftArrowClick = () => {
    const imagesCount = tigerData[selectedTigerIndex]?.images?.length || 0;
    if (imagesCount > 0) {
      setSelectedImageIndex((prevIndex) => {
        if (prevIndex > 0) {
          return prevIndex - 1;
        } else {
          return imagesCount - 1;
        }
      });
    }
  };

  const handleRightArrowClick = () => {
    const imagesCount = tigerData[selectedTigerIndex]?.images?.length || 0;
    if (imagesCount > 0) {
      setSelectedImageIndex((prevIndex) => {
        if (prevIndex < imagesCount - 1) {
          return prevIndex + 1;
        } else {
          return 0;
        }
      });
    }
  };

  useEffect(() => {
    if (tigerData.length > 0) {
      setSelectedTiger(
        tigerData[selectedTigerIndex]?.images[selectedImageIndex]?.image_key ||
          ""
      );
    }
  }, [tigerData, selectedImageIndex, selectedTigerIndex]);

  useEffect(() => {
    setIsPopupOpen(true);
  }, [classifiedSpecies, completeUpload]);

  useEffect(() => {
    const selectedOption = tigerData[selectedTigerIndex]?.name;
    const flag = selectedOption === activeButton ? null : selectedOption;
    if (tigerData.length > 0) {
      setActiveButton(flag);
    }
  }, [tigerData]);

  if (identifyButtonClicked === true) {
    return (
      <Tiger_routes
        tiger_id={tigerId}
        latitude={latitude}
        longitude={longitude}
        image={uploadedData[0].image}
        radio={selectedRadio}
        uploaded_data={uploadedData}
        setButtonClicked={setButtonClicked}
        buttonClicked={buttonClicked}
        types="upload_tiger"
      />
    );
  }

  const handleNameClick = (selectedOption) => {
    const flag = selectedOption === activeButton ? null : selectedOption;
    setActiveButton(flag);
    const selectedTigerIndex = tigerData.findIndex(
      (tiger) => tiger.name === selectedOption
    );
    if (selectedTigerIndex !== -1) {
      setSelectedTigerIndex(selectedTigerIndex);
      setSelectedImageIndex(0);
    }
  };

  const renderAdditionalContent = () => {
    return (
      <div
        style={{
          width: "%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flex: 1 }}>
          <div
            style={{
              marginTop: "-10px",
              marginLeft: "-390px",
              fontSize: "14px",
            }}
          >
            <strong>Uploaded Tiger Image</strong>
          </div>
          {classifiedSpecies === "Tiger" && tigerData.length > 0 ? (
            <>
              {tigerData.length > 1 && (
                <div
                  style={{
                    marginTop: "-20px",
                    marginRight: "-410px",
                    fontSize: "14px",
                    textAlign: "right",
                  }}
                >
                  <strong>
                    Matching Tiger ({tigerData[selectedTigerIndex].name})
                  </strong>
                </div>
              )}
              {tigerData.map((tiger, index) => (
                <StatsBar
                  key={tiger.name}
                  score={tiger.matching_score * 100}
                  name={
                    <button
                      key={index}
                      className={`Setting_Select_Button ${
                        tiger.name === activeButton ? "active" : ""
                      }`}
                      onClick={() => handleNameClick(tiger.name)}
                      style={{ minWidth: "75px" }}
                    >
                      {tiger.name}
                    </button>
                  }
                  isActive={tiger.name === activeButton}
                />
              ))}
            </>
          ) : classifiedSpecies !== "Tiger" ? (
            <div>No images to display</div>
          ) : (
            <div>Loading tiger predictions....</div>
          )}
        </div>
      </div>
    );
  };

  const handleRadioClick = (value) => {
    setSelectedRadio(value);
  };

  const handleLatitudeChange = (e) => {
    const value = e.target.value.replace(/[^0-9.-]/g, "");
    setLatitude(value);
    setPosition({ lat: parseFloat(value), lng: parseFloat(longitude) });
  };

  const handleLongitudeChange = (e) => {
    const value = e.target.value.replace(/[^0-9.-]/g, "");
    setLongitude(value);
    setPosition({ lat: parseFloat(latitude), lng: parseFloat(value) });
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="Settings_user">
      {loading && (
        <div className="loading-mask">
          <div style={{ textAlign: "center" }}>
            <ClipLoader
              color={"green"}
              loading={loading}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <p style={{ marginTop: "10px", color: "green" }}>
              Loading Matching Tigers...
            </p>
          </div>
        </div>
      )}
      {completeUpload > 0 && (
        <div>
          <div className="ImageCard">
            <div className="image-container">
              <div>
                <img
                  src={file[0].preview}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  alt=""
                />
              </div>
            </div>
            <div className="image-container">
              <div>
                {tigerData.length === 0 && <p>Loading Tiger Images.....</p>}

                {tigerData.length > 1 && (
                  <LazyImage
                    src={selectedTiger}
                    loading="lazy"
                    alt="Tiger image"
                    app={"tiger"}
                  />
                )}

                {tigerData[selectedTigerIndex]?.images.length > 1 && (
                  <div className="arrows-container">
                    <img
                      src={leftArrowIcon}
                      alt="Left Arrow"
                      className="left-arrow"
                      onClick={handleLeftArrowClick}
                    />
                    <img
                      src={rightArrowIcon}
                      alt="Right Arrow"
                      className="right-arrow"
                      onClick={handleRightArrowClick}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <div className={completeUpload > 0 ? "RightCard" : "UploadProperties"}>
          {tiger_api.upload ? (
            <UploadLoader
              setUpload={tiger_api.setUpload}
              percent={tiger_api.progress.uploadPercentage}
              total={tiger_api.progress.totalUpload}
              complete={tiger_api.progress.completeUpload}
              data={"Uploading Image..."}
            />
          ) : (
            completeUpload === 0 && (
              <>
                <div
                  className="UploadPropertiesHeading"
                  style={{ marginLeft: "-5px" }}
                >
                  <span>Assign Geolocation</span>
                </div>
                <div className="UploadFields">
                  <div className="UploadImagesSingle">
                    <div>
                      <img
                        src={file[0].preview}
                        style={{
                          width: "114%",
                          height: "auto",
                          marginLeft: "-15px",
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="UploadMapProperties">
                    <div style={{ height: "100%", width: "100%" }}>
                      <LoadScript googleMapsApiKey="AIzaSyDTg790iZlfKpDScxDQ9FMxSG0oPH8Oe8M">
                        <GoogleMap
                          mapContainerStyle={{ width: "100%", height: "100%" }}
                          center={
                            position === null
                              ? { lat: 11.962482, lng: 77.15502 }
                              : position
                          }
                          // default loc -  20.271597, 79.387850
                          // onClick={handleMarkerDragEnd}
                          zoom={9}
                        >
                          <Polygon
                            paths={polygonCoordinates}
                            options={{
                              fillColor: "skyblue",
                              fillOpacity: 0.4,
                              strokeColor: "blue",
                              strokeOpacity: 0.6,
                              strokeWeight: 2,
                            }}
                          />
                          {position === null ? (
                            ""
                          ) : (
                            <Marker
                              position={position}
                              draggable={true}
                              onDragEnd={handleMarkerDragEnd}
                            />
                          )}
                        </GoogleMap>
                      </LoadScript>
                    </div>
                  </div>
                  <div className="location_container">
                    <h5>
                      Add Latitude & Longitude or Choose a Location type &
                      Camera
                    </h5>
                    <div
                      className="UploadTigerParameters"
                      style={{ marginTop: "20px" }}
                    >
                      <div className="UploadTigerLocations">
                        <div className="PopupRadioButton">
                          <input
                            type="radio"
                            id="role"
                            name="role"
                            value="radio1"
                            checked={selectedRadio === "radio1"}
                            onChange={() => handleRadioClick("radio1")}
                          />
                        </div>
                        <div className="DirectionDropdown">
                          <label htmlFor="latitude">Latitude</label>
                          <input
                            type="text"
                            name="latitude"
                            value={latitude}
                            onChange={handleLatitudeChange}
                            placeholder="Latitude"
                            id="latitude"
                            disabled={selectedRadio !== "radio1"}
                            className={
                              selectedRadio !== "radio1" ? "disabledInput" : ""
                            }
                          />
                        </div>
                        <div className="DirectionDropdown">
                          <label htmlFor="">Longitude</label>
                          <input
                            type="text"
                            name="longitude"
                            value={longitude}
                            onChange={handleLongitudeChange}
                            placeholder="Longitude"
                            id="longitude"
                            disabled={selectedRadio !== "radio1"}
                            className={
                              selectedRadio !== "radio1" ? "disabledInput" : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="UploadTigerLocations">
                        <div className="PopupRadioButton">
                          <input
                            type="radio"
                            id="role"
                            name="role"
                            value="radio2"
                            checked={selectedRadio === "radio2"}
                            onChange={() => handleRadioClick("radio2")}
                          />
                        </div>
                        <div className="DirectionDropdown">
                          <label htmlFor="">Location type</label>
                          <select
                            onChange={locationChangeHandler}
                            required
                            disabled={selectedRadio !== "radio2"}
                            value={location_type}
                            className={
                              selectedRadio !== "radio2" ? "disabledInput" : ""
                            }
                          >
                            <option disabled selected hidden value="">
                              Select or Type Location
                            </option>
                            {range.map((value, key) => {
                              return (
                                <option
                                  className="role_filter_span"
                                  name={value.id}
                                  key={value.id}
                                  value={value.name}
                                >
                                  {value.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="DirectionDropdown">
                          <label htmlFor="">Select Camera</label>
                          <select
                            onChange={numberChangeHandler}
                            value={number}
                            required
                            disabled={selectedRadio !== "radio2"}
                            className={
                              selectedRadio !== "radio2" ? "disabledInput" : ""
                            }
                          >
                            <option disabled selected hidden value="">
                              Select or Type Number
                            </option>
                            {camera.map((value, key) => {
                              return (
                                <option
                                  className="role_filter_span"
                                  name={value.name}
                                  key={value.id}
                                  value={JSON.stringify(value)}
                                >
                                  {value.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="UploadTigerLocations">
                        <div
                          className="PopupRadioButton"
                          style={{ marginTop: "10px" }}
                        >
                          <input
                            type="radio"
                            id="role"
                            name="role"
                            value="radio3"
                            checked={selectedRadio === "radio3"}
                            onChange={() => handleRadioClick("radio3")}
                          />
                          <span style={{ marginLeft: "15px" }}>
                            Continue without assiging any Geo Location
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="UploadPropertiesButton">
                  <div onClick={cancelHandler} className="Cancel_button">
                    <span>Cancel</span>
                  </div>
                  <div
                    onClick={uploadHandler}
                    // disabled={error}
                    className={"Upload_button"}
                  >
                    <span>Upload</span>
                  </div>
                </div>
              </>
            )
          )}
          {completeUpload > 0 &&
            classifiedSpecies === "Tiger" &&
            renderAdditionalContent()}
        </div>
        {options && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "1vw",
              paddingRight: "20px",
              marginTop: "-110px",
              marginRight: "120px",
            }}
          >
            <button
              className="Setting_Confirm_Button"
              onClick={identifyHandler}
            >
              Confirm
            </button>
            <button
              className="Setting_Confirm_Button"
              onClick={handleOpenPopup}
            >
              Zoom View
            </button>
          </div>
        )}
      </div>

      <ToastContainer rtl={false} style={{ height: "10px" }} />
      <Popup
        open={isPopupOpen && !loading && completeUpload > 0}
        onClose={() => {
          setIsPopupOpen(false);
        }}
      >
        <div style={{ height: "90vh", width: "90vw" }}>
          <div>
            <div className="ImageCard">
              <div className="image-container">
                <div>
                  <img
                    src={file[0].preview}
                    style={{
                      width: "100%",
                      height: "60vh",
                    }}
                    alt=""
                  />
                </div>
              </div>
              <div className="image-container">
                <div>
                  {tigerData.length === 0 && <p>Loading Tiger Images.....</p>}

                  {tigerData.length > 1 && (
                    <LazyImage
                      src={selectedTiger}
                      loading="lazy"
                      alt="Popup Tiger"
                      app={"tiger"}
                    />
                  )}

                  {tigerData[selectedTigerIndex]?.images.length > 1 && (
                    <div className="arrows-container">
                      <img
                        src={leftArrowIcon}
                        alt="Left Arrow"
                        className="left-arrow"
                        onClick={handleLeftArrowClick}
                      />
                      <img
                        src={rightArrowIcon}
                        alt="Right Arrow"
                        className="right-arrow"
                        onClick={handleRightArrowClick}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                padding: "10px",
                fontSize: "16px",
                cursor: "pointer",
              }}
              onClick={handleClosePopup}
            >
              X
            </button>
            <div className="RightCard">
              {classifiedSpecies === "Tiger" && (
                <div
                  style={{
                    width: "%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginLeft: "-390px",
                        fontSize: "14px",
                      }}
                    >
                      <strong>Uploaded Tiger Image</strong>
                    </div>
                    {classifiedSpecies === "Tiger" && tigerData.length > 0 ? (
                      <>
                        {tigerData.length > 1 && (
                          <div
                            style={{
                              marginTop: "-20px",
                              marginRight: "-410px",
                              fontSize: "14px",
                              textAlign: "right",
                            }}
                          >
                            <strong>
                              Matching Tiger (
                              {tigerData[selectedTigerIndex].name})
                            </strong>
                          </div>
                        )}
                        {tigerData.map((tiger, index) => (
                          <StatsBar
                            key={tiger.name}
                            score={tiger.matching_score * 100}
                            name={
                              <button
                                key={index}
                                className={`Setting_Select_Button ${
                                  tiger.name === activeButton ? "active" : ""
                                }`}
                                onClick={() => handleNameClick(tiger.name)}
                                style={{ minWidth: "75px" }}
                              >
                                {tiger.name}
                              </button>
                            }
                            isActive={tiger.name === activeButton}
                          />
                        ))}
                      </>
                    ) : classifiedSpecies !== "Tiger" ? (
                      <div>No images to display</div>
                    ) : (
                      <div>Loading tiger predictions....</div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div>
              {options && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "20px",
                    marginTop: "-110px",
                    marginRight: "200px",
                  }}
                >
                  <button
                    className="Setting_Confirm_Button"
                    onClick={identifyHandler}
                  >
                    Confirm
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default UploadTiger;
