import React, { useContext } from "react";

import { useState, useEffect } from "react";

import axios from "axios";

import { url } from "../../Data/Data";

import Nodata from "../Nodata";

import NoDetail from "../NoDetail";

import { useNavigate } from "react-router-dom";

import { makeRequest } from "../../api";

import TadobaContext from "../Context/TadobaContext";
import YearFilter from "../YearFilter";

const Statistics_card = ({ pageLoading, setPageLoading, selectedYear, setSelectedYear }) => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const api = useContext(TadobaContext);

  const [data, setData] = useState([]);

  const getImages = async (year = 'all') => {
    let token = localStorage.token || "";

    try {
      const url = year === 'all'
        ? `api/v1/statistic/statistic_classification/`
        : `api/v1/statistic/statistic_classification/?year=${year}`;

      const result = await makeRequest("get", url);

      const data = result.data.data;

      setData(data);
      localStorage.setItem("statistic_classification", JSON.stringify(data));
      api.setTotalTigersCount(data.total_tiger_images);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    if (!localStorage.statistic_classification) {
      getImages();
    } else {
      const storedData = JSON.parse(localStorage.statistic_classification);
      setData(storedData);
      api.setTotalTigersCount(storedData.total_tiger_images);
    }
  }, []);

  useEffect(() => {
    console.log(api.selectedYearData)
    const fetchByYear = async () => {
      if (api.selectedYearData) {
        if (api.selectedYearData === 'all') {
          getImages();
        } else {
          getImages(api.selectedYearData);
        }
      }
    };

    fetchByYear();
  }, [api.selectedYearData]);


  return (
    <div className="Card">
      <div className="Card_Heading">
        <div className="Card_title">
          <img src={require("../../Assets/Icons/Card_Stat.png")} alt="" />

          <span>Classification</span>
        </div>

        <div style={{display: "flex", gap: "2vw", alignItems: "center"}}>
          <YearFilter loading={loading} setLoading={setPageLoading} selectedYear={selectedYear} setSelectedYear={setSelectedYear}/>
          <div
            className="Card_Link"
            onClick={() => {
              navigate("/images/");
            }}
          >
            <span>View all</span>
          </div>
        </div>

      </div>

      {data.length === 0 ? (
        <NoDetail loading={pageLoading} />
      ) : (
        <div className="Stat_Card_Content">
          <div className="stat_element">
            <span className="stat_title">Total Images</span>

            <span className="stat_count">{data.total_images}</span>
          </div>

          <div className="stat_element">
            <span className="stat_title">Total Classified images</span>

            <span className="stat_count">{data.total_classified_images}</span>
          </div>

          <div className="stat_element">
            <span className="stat_title">Total Unclassified images</span>

            <span className="stat_count">{data.total_unclassified_images}</span>
          </div>

          <div className="stat_element">
            <span className="stat_title">Total Tiger images</span>

            <span className="stat_count">{data.total_tiger_images}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Statistics_card;
